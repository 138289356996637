const directionsList = document.querySelector(`.js-directions-list`);
const directionsItems = document.querySelectorAll(`.js-directions-list li`);
const radioLetters = document.querySelectorAll(`.js-letters-block input`);

const returnCheckedRadioValue = () => {
  let currentLetter = '';
  if (radioLetters[0].checked) {
    return 'all';
  }

  radioLetters.forEach((el) => {
    if (el.checked) {
      currentLetter = el.value.toLowerCase();
    }
  });
  return currentLetter;
};

const filterDirection = () => {
  directionsList.innerHTML = '';

  if (returnCheckedRadioValue() === 'all') {
    directionsItems.forEach((el) => {
      directionsList.appendChild(el);
    });
  }

  const arr = [];
  directionsItems.forEach((el) => {
    if (el.querySelector('.nav-block__specialisation-title').textContent.charAt(0).toLowerCase() === returnCheckedRadioValue()) {
      arr.push(el);
    }
  });

  arr.forEach((el) => {
    directionsList.appendChild(el);
  });
};

const directionsForm = document.querySelector(`.nav-block__search-form form`);

const el = document.querySelector(`.nav-block__content`);

const changeDirectionsFormState = () => {
  if (directionsForm) {
    directionsForm.addEventListener(`change`, () => {
      directionsList.classList.add(`doctor-nav__specialisation-list--no-opacity`);

      el.style = `position: relative`;

      setTimeout(() => {
        filterDirection();
        directionsList.classList.remove(`doctor-nav__specialisation-list--no-opacity`);

        el.style = `position: unset`;
      }, 300);
    });
  }
};

export default changeDirectionsFormState;
