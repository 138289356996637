
const elements = document.querySelectorAll('[data-click-goal]');

const reachGoal = (name, type = 'submit') => {
  if (name !== "") {
    if(typeof window.Ya === "object") {
      window.ym(window.Ya.Metrika2.counters()[0].id, 'reachGoal', name);
    }
    if(typeof window.gtag != "undefined") {
      window.gtag('event', type, {'event_category': name});
    }
  }
};

const initAnalytics = () => {

  elements.forEach((el) => {
    el.addEventListener('click', () => {
      reachGoal(el.dataset.clickGoal);
    });
  });

};

export {initAnalytics, reachGoal};
