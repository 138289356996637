const asideBlock = document.querySelector('.aside');
const classNoFix = 'aside--no-fix';
const header = document.querySelector('.header');
const headerHeight = header ? header.getBoundingClientRect().height : 0;
const PADDING = 20 + headerHeight;

// console.log('headerHeight', headerHeight);

const addFixedPosition = () => {
  asideBlock.classList.add('aside--fixed');
};

const removeFixedPosition = () => {
  asideBlock.classList.remove('aside--fixed');
};

const fixAsideBlock = () => {
  if (asideBlock && !asideBlock.classList.contains(classNoFix)) {
    window.addEventListener('scroll', () => {
      const offset = window.pageYOffset;
      const topBlock = asideBlock.getBoundingClientRect().top + offset - PADDING;
      const content = asideBlock.querySelector('.aside__content');
      if (offset > topBlock) {
        addFixedPosition();
        content.style.top = PADDING + 'px';
      } else {
        removeFixedPosition();
        content.style.top = null;
      }
    });
  }
};

export default fixAsideBlock;
