const tableBlocks = document.querySelectorAll(`.block-image__content-wrap .price-table-wrap`);
const showMoreBtns = document.querySelectorAll(`.block-image__content-wrap .show-more`);
const emptyModal = document.querySelector(`.empty-modal__content`);


const initShowMoreBtnOnTable = () => {
  if (!tableBlocks.length) {
    return;
  }

  showMoreBtns.forEach((el) => {
    el.addEventListener(`click`, () => {
      emptyModal.innerHTML = el.previousElementSibling.innerHTML;
    });
  });

  tableBlocks.forEach((el) => {
    if (el.scrollHeight > el.clientHeight) {
      console.log('hi');
      el.closest(`.block-image--with-table`).classList.add(`js-show-more`);
    } else {
      console.log('no');
      el.closest(`.block-image--with-table`).classList.remove(`js-show-more`);
    }
  });
};

export {initShowMoreBtnOnTable};
