import customPlaceholderAction from './custom-placeholder';
import {maskDepartmentCode, unmaskDepartmentCode} from './validate-department-code';

const form = document.querySelector('.js-submit-documents-form');

const requiredPatientFields = [
  'form_text_5',
  'form_text_6',
  'form_text_7',
  'form_date_8',
  'form_email_9',
  'form_text_10',
  'form_text_11',
  'form_text_12',
  'form_text_13',
  'form_text_14',
  'form_text_15',
  'form_text_16',
  'form_text_17',
  'form_text_18'
];
const requiredPatientFieldsEn = [
  'form_text_5',
  'form_text_6',
  'form_date_8',
  'form_email_9',
  'form_text_10',
  'form_text_12',
  'form_text_14',
  'form_text_15',
  'form_text_16',
  'form_text_17',
  'form_text_18'
];
const requiredChildWithPassportFields = [
  'form_text_33',
  'form_text_34',
  'form_text_35',
  'form_date_36',
  'form_text_37',
  'form_text_38',
  'form_text_39',
  'form_text_40',
  'form_date_41',
  'form_text_42',
  'form_text_43',
  'form_text_44',
  'form_text_105',
  'form_text_106',
  'form_text_107',
  'form_date_108',
  'form_email_109',
  'form_text_110',
  'form_text_111',
  'form_text_112',
  'form_text_114',
  'form_text_113',
  'form_date_115',
  'form_text_116',
  'form_text_117',
  'form_text_118'
];
const requiredChildWithPassportFieldsEn = [
  'form_text_33',
  'form_text_34',
  'form_date_36',
  'form_text_38',
  'form_text_40',
  'form_date_41',
  'form_text_42',
  'form_text_43',
  'form_text_44',
  'form_text_105',
  'form_text_106',
  'form_date_108',
  'form_email_109',
  'form_text_110',
  'form_text_112',
  'form_text_114',
  'form_date_115',
  'form_text_116',
  'form_text_117',
  'form_text_118'
];
const requiredChildWithSertificateFields = [
  'form_text_33',
  'form_text_34',
  'form_text_35',
  'form_date_36',
  'form_text_30',
  'form_text_31',
  'form_date_32',
  'form_text_42',
  'form_text_43',
  'form_text_44',
  'form_text_105',
  'form_text_106',
  'form_text_107',
  'form_date_108',
  'form_email_109',
  'form_text_110',
  'form_text_111',
  'form_text_112',
  'form_text_114',
  'form_text_113',
  'form_date_115',
  'form_text_116',
  'form_text_117',
  'form_text_118'
];
const requiredChildWithSertificateFieldsEn = [
  'form_text_33',
  'form_text_34',
  'form_date_36',
  'form_text_30',
  'form_text_31',
  'form_date_32',
  'form_text_42',
  'form_text_43',
  'form_text_44',
  'form_text_105',
  'form_text_106',
  'form_date_108',
  'form_email_109',
  'form_text_110',
  'form_text_112',
  'form_text_114',
  'form_date_115',
  'form_text_116',
  'form_text_117',
  'form_text_118'
];
const requiredAdultEnFields = [
  'form_text_5',
  'form_text_6',
  'form_date_8',
  'form_email_9',
  'form_text_10',
  'form_text_12',
  'form_text_14',
  'form_date_15',
  'form_text_16',
  'form_text_17',
  'form_text_18',
  'form_text_162'
];
const requiredChildEnFields = [
  'form_text_33',
  'form_text_34',
  'form_text_163',
  'form_date_36',
  'form_email_164',
  'form_text_165',
  'form_text_38',
  'form_text_40',
  'form_date_41',
  'form_text_42',
  'form_text_43',
  'form_text_44',
  'form_text_105',
  'form_text_106',
  'form_text_166',
  'form_date_108',
  'form_email_109',
  'form_text_110',
  'form_text_112',
  'form_text_114',
  'form_date_115',
  'form_text_116',
  'form_text_117',
  'form_text_118'
];

const changeRequiredFields = (mode) => {

  let isChildRuEn = form.classList.contains('submit-documents__form--child-ru-en');

  switch (mode) {
    case 'adult':
      changeFields(requiredPatientFields);
      maskDepartmentCode();
      break;
    case 'adult-ru-en':
      changeFields(requiredPatientFieldsEn);
      unmaskDepartmentCode();
      break;
    case 'child':
      changeFields(requiredChildWithSertificateFields);
      maskDepartmentCode();
      setTimeout(function() {
        let checkbox = document.querySelector('#birth-certificate');
        checkbox.checked = true;
        checkbox.dispatchEvent(new Event('click'));
      }, 50);
      break;
    case 'child-ru-en':
      changeFields(requiredChildWithPassportFieldsEn);
      unmaskDepartmentCode();
      setTimeout(function() {
        let checkbox = document.querySelector('#passport');
        checkbox.checked = true;
        checkbox.dispatchEvent(new Event('click'));
      }, 50);
      break;
    case 'passport':
      changeFields(isChildRuEn ? requiredChildWithPassportFieldsEn : requiredChildWithPassportFields);
      unmaskDepartmentCode();
      if(!isChildRuEn) {
        maskDepartmentCode();
      }
      break;
    case 'birthСertificate':
      changeFields(isChildRuEn ? requiredChildWithSertificateFieldsEn : requiredChildWithSertificateFields);
      unmaskDepartmentCode();
      if(!isChildRuEn) {
        maskDepartmentCode();
      }
      break;
    case 'en-adult':
      changeFields(requiredAdultEnFields);
      break;
    case 'en-child':
      changeFields(requiredChildEnFields);
      break;
  }
};

const changeFields = (newReqiredFields) => {
  const currentlyRequiredFields = form.querySelectorAll('input[required="required"]');
  if (currentlyRequiredFields.length) {
    currentlyRequiredFields.forEach((el) => {
      el.removeAttribute('required');
    });
  }
  newReqiredFields.forEach((el) => {
    if (form.querySelector(`input[name="${el}"]`)) {
      form.querySelector(`input[name="${el}"]`).setAttribute('required', 'required');
    }
  });
  customPlaceholderAction();
};

export default changeRequiredFields;
