const filteringCounter = (link, data) => {
  const atr = link.getAttribute(`data-specialisation`);
  const counter = link.querySelector('.doctor-nav__specialisation-counter') || link.parentNode.querySelector('.doctor-nav__specialisation-counter');
  const result = data.filter((el) => el.specialisation && el.specialisation.includes(atr));

  if (result.length) {
    link.parentNode.style.display = 'flex';
    counter.innerHTML = result.length;
  } else {
    link.parentNode.style.display = 'none';
    counter.innerHTML = ``;
  }
};

const setSpecialisationCounter = (links, data) => {
  links.forEach((link) => {
    filteringCounter(link, data);
  });
};

export default setSpecialisationCounter;
