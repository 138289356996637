import {returnParent} from '../utils';

const addressCheckboxes = document.querySelectorAll(`.js-address-agreement input`);

const addressCheckboxesAction = () => {
  if (addressCheckboxes) {
    addressCheckboxes.forEach((el) => {
      toggleRelatedAddressFields(el);
      el.addEventListener(`change`, () => {
        toggleRelatedAddressFields(el);
      });
    });
  }
};

const toggleRelatedAddressFields = (el) => {
  const parent = returnParent(el, `submit-documents__fieldset`);
  const relatedResidenceAddressField = parent.querySelector(`.submit-documents__subfieldset--residence-address`);
  if (el.checked) {
    relatedResidenceAddressField.classList.add(`hidden`);
  } else {
    relatedResidenceAddressField.classList.remove(`hidden`);
  }
};

export default addressCheckboxesAction;
