
const olol = () => {
  const tabs = document.querySelectorAll('.gms-tabs__list a');
  const tabContents = document.querySelectorAll('.gms-tabs__item');

  function removeActiveClass() {
    tabs.forEach((tab) => {
      tab.classList.remove('active');
    });
    tabContents.forEach((tab) => {
      tab.classList.remove('active');
    });
  }

  tabs.forEach((tab) => {
    tab.addEventListener('click', function(e) {
      e.preventDefault();
      removeActiveClass();
      this.classList.add('active');
      document.querySelector(`${this.getAttribute('href')}`).classList.add('active');
    });
  });
};

const createYoutubeIframe = (id) => {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', `https://www.youtube.com/embed/${id}?autoplay=1&rel=0&showinfo=0&autohide=1`);
  iframe.setAttribute('allowfullscreen', '');
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share');

  return iframe;
};

const initYoutubePlayer = () => {
  const players = document.querySelectorAll('.youtube-video');
  players.forEach((player) => {
    if (!player.dataset.youtube) {
      return;
    }

    const id = player.dataset.youtube;
    player.style.backgroundImage = `url(https://img.youtube.com/vi/${id}/maxresdefault.jpg)`;

    player.addEventListener('click', () => {
      player.style.backgroundImage = null;
      const iframe = createYoutubeIframe(id);
      player.appendChild(iframe);
    });
  });
};

export {initYoutubePlayer};
