
import {returnParent, getParent} from '../utils';

const tooltip = document.querySelectorAll(`.js-tooltip`);

const tooltipClasses = [`tooltip--active`, `tooltip--left-bottom`, `tooltip--left-top`, `tooltip--right-bottom`, `tooltip--right-top`, `tooltip--center-top`, `tooltip--center-bottom`];

const closeAllTooltip = () => {
  tooltip.forEach((el) => {
    const contentBlock = el.parentNode.querySelector(`.tooltip__content`);
    el.parentNode.classList.remove(...tooltipClasses);
    window.removeEventListener(`click`, onWindowClick);
    contentBlock.style.left = null;
  });
};

const openTooltip = (evt) => {
  evt.preventDefault();
  closeAllTooltip();
  evt.target.parentNode.classList.add(`tooltip--active`);
  setTooltipPosition(evt.target);
  window.addEventListener(`click`, onWindowClick);
};

const closeTooltip = (evt) => {
  evt.preventDefault();
  const closeBtn = evt.target.querySelector(`.tooltip__close-btn`);
  closeBtn.addEventListener(`click`, onCloseBtnClick);
  closeAllTooltip();
  evt.target.parentNode.classList.remove(`tooltip--active`);
  window.removeEventListener(`click`, onWindowClick);
};

const onCloseBtnClick = (evt) => {
  evt.preventDefault();
  const parent = returnParent(evt.target, `tooltip`);
  parent.classList.remove(`tooltip--active`);
};

const onWindowClick = (evt) => {
  if (getParent(evt.target, `tooltip`)) {
    closeAllTooltip();
    window.removeEventListener(`click`, onWindowClick);
  }
};

const setTooltipPosition = (el) => {
  const offsetLeft = el.parentNode.getBoundingClientRect().left;
  const offsetTop = el.parentNode.getBoundingClientRect().top;
  const contentBlock = el.parentNode.querySelector(`.tooltip__content`);
  if (contentBlock) {
    const height = contentBlock.scrollHeight;
    const width = contentBlock.offsetWidth;
    const windowWidth = document.body.clientWidth;
    const offsetRight = windowWidth - offsetLeft;
    const offsetCompensation = (windowWidth - width) / 2;

    let cssClass;

    if (offsetLeft > width / 2 && offsetRight > width / 2) {
      cssClass = `tooltip--center`;
    } else if (offsetLeft > width + 30) {
      cssClass = `tooltip--left`;
    } else if (offsetRight > width + 30) {
      cssClass = `tooltip--right`;
    } else {
      contentBlock.style.left = -offsetLeft + offsetCompensation + `px`;
    }

    if (offsetTop >= height + 100) {
      cssClass = cssClass + `-top`;
    } else if (offsetTop < height + 100) {
      cssClass = cssClass + `-bottom`;
    }
    el.parentNode.classList.add(cssClass);
  }
};

const tooltipAction = () => {
  if (tooltip.length) {
    tooltip.forEach((el) => {
      if (el.parentNode.classList.contains(`tooltip--active`)) {
        el.addEventListener(`click`, closeTooltip);
      } else {
        const closeBtn = el.parentNode.querySelector(`.tooltip__close-btn`);
        el.addEventListener(`click`, openTooltip);
        closeBtn.addEventListener(`click`, onCloseBtnClick);
      }
    });
  }
};

export default tooltipAction;
