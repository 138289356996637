const infoModal = document.querySelector(`.tooltip-modal`);
const renderModalBtns = document.querySelectorAll(`.js-tooltip-modal-btn`);

const renderTooltipModalContent = () => {
  if (renderModalBtns.length && infoModal) {
    renderModalBtns.forEach((btn) => {
      btn.addEventListener(`click`, () => {
        infoModal.innerHTML = ``;
        const content = btn.nextElementSibling.querySelector(`.tooltip__content-block`).innerHTML;
        infoModal.innerHTML = content;
      });
    });
  }
};

export default renderTooltipModalContent;
