const sliderWrapers = document.querySelectorAll('.swiper-wrapper');
const onlyDesktopNavigationModSliders = document.querySelectorAll('.js-only-desktop-navigation-mod');
const carouselModSliders = document.querySelectorAll('.js-carusel-mod');
const carouselModSlidersCustom = document.querySelectorAll('.js-carusel-mod-custom');
const carouselModVideoSliders = document.querySelectorAll('.js-carusel-mod-video');
const carouselNavigationModSliders = document.querySelectorAll('.js-carousel-navigation-mod');
const noLoopSliders = document.querySelectorAll('.js-no-loop-slider');
const carouselModNoLoopMobileSliders = document.querySelectorAll('.js-carusel-mod-no-loop-mobile');
const carouselModNoLoopSliders = document.querySelectorAll('.js-carusel-mod-no-loop');
const tabWrappers = document.querySelectorAll('.tabs-block__tab-item-wrapper');
const noLoopWithSpaceSliders = document.querySelectorAll('.js-no-loop-mod-space-between');


let swiperList = [];

const navigationModeSettings2 = {
  slidesPerView: 'auto',
  loop: true,
  spaceBetween: 30,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  observer: true,
  observeParents: true
};

const carouselModeVideoSettings = {
  slidesPerView: 2,
  // loop: true,
  // spaceBetween: 30,
  // updateOnWindowResize: true,
  slidesOffsetAfter: 120,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
};

const carouselModeSettings = {
  slidesPerView: 'auto',
  // loop: true,
  spaceBetween: 30,
  // updateOnWindowResize: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  observer: true,
  observeParents: true
};

const carouselModeSettings2 = {
  slidesPerView: 'auto',
  // loop: true,
  spaceBetween: 30,
  // updateOnWindowResize: true,
  navigation: {
    nextEl: '.swiper-button-next2',
    prevEl: '.swiper-button-prev2',
  }
};

const noLoopWithSpaceModeSettings2 = {
  slidesPerView: 'auto',
  spaceBetween: 30,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
};

const noLoopWithSpaceModeSettings3 = {
  slidesPerView: 'auto',
  slidesOffsetBefore: 20,
  slidesOffsetAfter: 0,
  updateOnWindowResize: true,
  spaceBetween: 8,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
};

const carouselModeSettingsNoLoopAll = {
  slidesPerView: 'auto',
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
};

const storiesModeSettings2 = {
  loop: true,
  speed: 600,
  slidesPerView: 'auto',
  pagination: {
    el: '.js-carousel-navigation-mod-pagination',
    clickable: true
  },
};

const carouselModeSettingsNoLoop = {
  slidesPerView: 'auto'
};

const initSlider = (el, settings) => {
  // eslint-disable-next-line no-undef
  const parent = el.closest('.services-cards-slider');
  if(parent && settings.navigation.nextEl === '.swiper-button-next')
  {
    settings.navigation.nextEl = parent.querySelector('.swiper-button-next');
    settings.navigation.prevEl = parent.querySelector('.swiper-button-prev');
  }

  const swiper = new Swiper(el, settings);
  swiperList.push(swiper);
};

const breakpoint = window.matchMedia('(max-width:1023px)');
const breakpoint2 = window.matchMedia('(max-width:1440px)');

const setexpendedServicesCardSlider = () => {
  swiperList = [];
  if (sliderWrapers.length) {
    /*
    Array.prototype.forEach.call(tabWrappers, (el) => {
      const wrapper = el;
      if (wrapper.parentNode.classList.contains(`hidden`)) {
        wrapper.parentNode.classList.remove(`hidden`);
        setTimeout(() => {
          wrapper.parentNode.classList.add(`hidden`);
        }, 300);
      }
    });
    */
    if (carouselNavigationModSliders) {
      if (breakpoint.matches === false && !document.body.classList.contains('blind')) {
        Array.prototype.forEach.call(carouselNavigationModSliders, (item) => {
          initSlider(item, carouselModeSettings);
        });
      }
      if (breakpoint2.matches === true && document.body.classList.contains('blind')) {
        Array.prototype.forEach.call(carouselNavigationModSliders, (item) => {
          initSlider(item, storiesModeSettings2);
        });
      }
      if (carouselNavigationModSliders) {
        if (breakpoint.matches === true) {
          Array.prototype.forEach.call(carouselNavigationModSliders, (item) => {
            initSlider(item, storiesModeSettings2);
          });
        }
      }
    }
    if (onlyDesktopNavigationModSliders) {
      if (breakpoint.matches === false) {
        Array.prototype.forEach.call(onlyDesktopNavigationModSliders, (item) => {
          item.firstElementChild.classList.add('swiper-wrapper');
          initSlider(item, navigationModeSettings2);
        });
      }
    }
    if (carouselModSliders) {
      Array.prototype.forEach.call(carouselModSliders, (item) => {
        initSlider(item, carouselModeSettings);
      });
    }
    if (carouselModSlidersCustom) {
      Array.prototype.forEach.call(carouselModSlidersCustom, (item) => {
        initSlider(item, carouselModeSettings2);
      });
    }
    if (carouselModVideoSliders) {
      Array.prototype.forEach.call(carouselModVideoSliders, (item) => {
        initSlider(item, carouselModeVideoSettings);
      });
    }


    if (noLoopWithSpaceSliders) {
      if (breakpoint.matches === false) {
        Array.prototype.forEach.call(noLoopWithSpaceSliders, (item) => {
          initSlider(item, noLoopWithSpaceModeSettings2);
        });
      }
      if (breakpoint.matches === true) {
        Array.prototype.forEach.call(noLoopWithSpaceSliders, (item) => {
          initSlider(item, noLoopWithSpaceModeSettings3);
        });
      }
    }

    if (carouselModNoLoopSliders) {
      Array.prototype.forEach.call(carouselModNoLoopSliders, (item) => {
        initSlider(item, carouselModeSettingsNoLoopAll);
      });
    }

    if (onlyDesktopNavigationModSliders) {
      if (breakpoint.matches === true) {
        Array.prototype.forEach.call(onlyDesktopNavigationModSliders, (item) => {
          item.firstElementChild.classList.remove('swiper-wrapper');
        });
      }
    }

    if (noLoopSliders) {
      Array.prototype.forEach.call(noLoopSliders, (item) => {
        initSlider(item, carouselModeSettingsNoLoop);
      });
    }

    if (carouselModNoLoopMobileSliders) {
      if (breakpoint.matches === false) {
        Array.prototype.forEach.call(carouselModNoLoopMobileSliders, (item) => {
          initSlider(item, carouselModeSettings);
        });
      }
      if (breakpoint.matches === true) {
        Array.prototype.forEach.call(carouselModNoLoopMobileSliders, (item) => {
          initSlider(item, carouselModeSettingsNoLoop);
        });
      }
    }
  }
};

const checkmobileWidth = () => {
  if (breakpoint.matches === true) {
    return true;
  }
  return false;
};

let isMobileWidth = checkmobileWidth();

const resizeSwiper = () => {
  if (checkmobileWidth() !== isMobileWidth) {
    isMobileWidth = !isMobileWidth;

    swiperList.forEach(function (swiper) {
      if (swiper.length) {
        swiper.forEach(function (el) {
          el.destroy();
        });
      } else {
        swiper.destroy();
      }
    });

    swiperList = [];
    setexpendedServicesCardSlider();
  }
};

window.addEventListener('resize', resizeSwiper);


export default setexpendedServicesCardSlider;
