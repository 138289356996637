import {openModal, closeModal} from './modals';
import {disableScrolling} from './utils';
import {setCookie, getCookie} from "../util/cookie";

const modal = document.querySelector('.js-make-cta-modal');

const initInstagramModal = () => {
  if (modal) {

    const modalCookieShown = !!getCookie(modal.dataset.modalId);

    if (!modalCookieShown) {
      let modalsShown = false;

      if (modal.querySelector('.js-make-appointment-btn')) {
        modal.querySelector('.js-make-appointment-btn').addEventListener('click', (event) => {
          event.preventDefault();
          closeModal(modal);
          setTimeout(disableScrolling, 350);
        });
      }

      window.addEventListener('scroll', () => {
        const content = document.querySelector('.js-show-modals-after');

        if ((window.scrollY > content.offsetTop + content.offsetHeight) && !modalsShown) {
          modalsShown = true;

          setCookie(modal.dataset.modalId, 'shown', 1);
          openModal(modal);
        }
      });
    }
  }
};

export {initInstagramModal};
