const tabSortBtns = document.querySelectorAll(`.btn--tag-xl`);

const activeTabSortBtn = () => {
  if (tabSortBtns.length) {
    tabSortBtns.forEach((btn) => {
      if (!btn.classList.contains('filter-select')) {
        const closeBtn = btn.querySelector(`svg`);
        if (closeBtn) {
          closeBtn.addEventListener(`click`, () => {
            btn.remove();
          });
        }
      }
    });
  }
};

export default activeTabSortBtn;
