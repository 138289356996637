import hyphenate from "./hyphenate";

const blindModBtn = document.querySelector(`.js-blind-mod-btn`);
const returnModBtn = document.querySelector(`.header-mod-btn`);
const root = document.querySelector(`html`);

const fontSizesDefault = {
  'h1-size': `75px`,
  'h2-size': `52px`,
  'h3-size': `38px`,
  'h4-size': `30px`,
  // 'plain-text-xxl-size': `36px`,
  'plain-text-xl-x-size': `28px`,
  'plain-text-xl-size': `25px`,
  'plain-text-l-x-size': `22px`,
  'plain-text-l-size': `21px`,
  'plain-text-m-size': `17px`,
  'plain-text-s-size': `15px`,
  'plain-text-xs-size': `13px`,
  'plain-text-xxs-size': `11px`,

  'fs-10': `10px`,
  'fs-12': `12px`,
  'fs-14': `14px`,
  'fs-16': `16px`,
  'fs-18': `18px`,
  'fs-19': `19px`,
  'fs-20': `20px`,
  'fs-24': `24px`,
  'fs-26': `26px`,
  'fs-32': `32px`,
  'fs-34': `34px`,
  'fs-35': `35px`,
  'fs-37': `37px`,
  'fs-40': `40px`,
  'fs-42': `42px`,
  'fs-44': `44px`,
  'fs-45': `45px`,
  'fs-54': `54px`,
  'fs-55': `55px`,
  'fs-57': `57px`,
  'fs-58': `58px`,
  'fs-60': `60px`,
  'fs-64': `64px`,
};

const fontSizesBlind = {
  'h1-size': `112px`,
  'h2-size': `78px`,
  'h3-size': `57px`,
  'h4-size': `45px`,
  // 'plain-text-xxl-size': `55px`,
  'plain-text-xl-x-size': `42px`,
  'plain-text-xl-size': `37px`,
  'plain-text-l-x-size': `33px`,
  'plain-text-l-size': `31px`,
  'plain-text-m-size': `25px`,
  'plain-text-s-size': `22px`,
  'plain-text-xs-size': `20px`,
  'plain-text-xxs-size': `15px`,

  'fs-10': `15px`,
  'fs-12': `18px`,
  'fs-14': `21px`,
  'fs-16': `23px`,
  'fs-18': `27px`,
  'fs-19': `29px`,
  'fs-20': `30px`,
  'fs-24': `36px`,
  'fs-26': `39px`,
  'fs-32': `48px`,
  'fs-34': `51px`,
  'fs-35': `53px`,
  'fs-37': `56px`,
  'fs-40': `60px`,
  'fs-42': `63px`,
  'fs-44': `66px`,
  'fs-45': `68px`,
  'fs-54': `81px`,
  'fs-55': `83px`,
  'fs-57': `86px`,
  'fs-58': `87px`,
  'fs-60': `90px`,
  'fs-64': `96px`,
};

const lineHeightsDefault = {
  'lh-14': `14px`,
  'lh-16': `16px`,
  'lh-17': `17px`,
  'lh-18': `18px`,
  'lh-20': `20px`,
  'lh-21': `21px`,
  'lh-22': `22px`,
  'lh-23': `23px`,
  'lh-24': `24px`,
  'lh-25': `25px`,
  'lh-26': `26px`,
  'lh-27': `27px`,
  'lh-28': `28px`,
  'lh-29': `29px`,
  'lh-30': `30px`,
  'lh-32': `32px`,
  'lh-34': `34px`,
  'lh-35': `35px`,
  'lh-36': `36px`,
  'lh-37': `37px`,
  'lh-38': `38px`,
  'lh-40': `40px`,
  'lh-42': `42px`,
  'lh-44': `44px`,
  'lh-45': `45px`,
  'lh-46': `46px`,
  'lh-48': `48px`,
  'lh-50': `50px`,
  'lh-52': `52px`,
  'lh-54': `54px`,
  'lh-55': `55px`,
  'lh-57': `57px`,
  'lh-58': `58px`,
  'lh-60': `60px`,
  'lh-64': `64px`,
  'lh-68': `68px`,
  'lh-71': `71px`,
  'lh-72': `72px`,
  'lh-80': `80px`,
  'lh-98': `98px`,
};

const lineHeightsBlind = {
  'lh-16': `24px`,
  'lh-17': `26px`,
  'lh-18': `27px`,
  'lh-14': `17px`,
  'lh-20': `30px`,
  'lh-21': `32px`,
  'lh-22': `33px`,
  'lh-23': `35px`,
  'lh-24': `36px`,
  'lh-25': `38px`,
  'lh-26': `39px`,
  'lh-27': `41px`,
  'lh-28': `42px`,
  'lh-29': `44px`,
  'lh-30': `45px`,
  'lh-32': `48px`,
  'lh-34': `51px`,
  'lh-35': `53px`,
  'lh-36': `54px`,
  'lh-37': `56px`,
  'lh-38': `57px`,
  'lh-40': `60px`,
  'lh-42': `63px`,
  'lh-44': `66px`,
  'lh-45': `68px`,
  'lh-46': `69px`,
  'lh-48': `72px`,
  'lh-50': `75px`,
  'lh-52': `78px`,
  'lh-54': `81px`,
  'lh-55': `83px`,
  'lh-57': `86px`,
  'lh-58': `87px`,
  'lh-60': `90px`,
  'lh-64': `96px`,
  'lh-68': `98px`, // 102
  'lh-71': `98px`, // 107
  'lh-72': `98px`, // 108
  'lh-80': `98px`, // 120
  'lh-98': `98px`,
};

const setVaraibles = (set) => {
  if (set) {
    Object.keys(fontSizesBlind).forEach((key) => {
      let newProperty = `--${key}`;
      let newValue = fontSizesBlind[key];
      root.style.setProperty(newProperty, newValue);
    });

    Object.keys(lineHeightsBlind).forEach((key) => {
      let newProperty = `--${key}`;
      let newValue = lineHeightsBlind[key];
      root.style.setProperty(newProperty, newValue);
    });

  } else {
    Object.keys(fontSizesDefault).forEach((key) => {
      let newProperty = `--${key}`;
      let newValue = fontSizesDefault[key];
      root.style.setProperty(newProperty, newValue);
    });

    Object.keys(lineHeightsDefault).forEach((key) => {
      let newProperty = `--${key}`;
      let newValue = lineHeightsDefault[key];
      root.style.setProperty(newProperty, newValue);
    });
  }
};

if (localStorage.getItem(`blind-mod`)) {
  document.body.classList.add(`blind`);
  setVaraibles(true);
  returnModBtn.classList.add(`js-show`);
}

const onBlindModBtnClick = (evt) => {
  evt.preventDefault();
  if (!document.body.classList.contains(`blind`)) {
    document.body.classList.add(`blind`);
    setVaraibles(true);
    returnModBtn.classList.add(`js-show`);
    localStorage.setItem(`blind-mod`, `true`);
    hyphenate();
    return;
  }
};

const onReturnModBtnClick = (evt) => {
  evt.preventDefault();
  if (document.body.classList.contains(`blind`)) {
    document.body.classList.remove(`blind`);
    setVaraibles(false);
    returnModBtn.classList.remove(`js-show`);
    localStorage.removeItem(`blind-mod`);
    hyphenate();
  }
};

const setBlindMod = () => {
  if (blindModBtn) {
    blindModBtn.addEventListener(`click`, onBlindModBtnClick);
  }

  if (returnModBtn) {
    returnModBtn.addEventListener(`click`, onReturnModBtnClick);
  }
};

export default setBlindMod;
