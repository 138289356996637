const doctorSearchBlock = document.querySelector(`.doctor-search`);
const doctorSearchInput = document.querySelector(`.doctor-search .search-form__input input`);
const doctorNav = document.querySelector(`.doctor-nav`);
const doctorNavBtn = document.querySelector(`.doctor-nav__button`);
const specialisationLink = document.querySelectorAll(`.doctor-nav__specialisation-link`);
const specialisationResultBlock = document.querySelector(`.doctor-nav__search-result`);
const searchBlock = document.querySelector(`.doctor-nav__search-block`);
const specialisationResultLink = document.querySelector(`.doctor-nav__specialisation-page-link a`);
const mobileCloseDoctors = document.querySelector('.js-mobile-doctors-close');
const searchWrapper = document.querySelector('.doctor-search__search-block');

const sortingResultLink = (evt) => {
  const specialisation = evt.target.getAttribute('data-specialisation');
  const specialisationResultLink = document.querySelectorAll('.doctor-nav__result-link');
  specialisationResultLink.forEach((link) => {
    link.parentNode.classList.add(`doctor-nav__result-item--hidden`);
    const atr = link.getAttribute(`data-specialisation`);
    if (atr.includes(specialisation)) {
      link.parentNode.classList.remove(`doctor-nav__result-item--hidden`);
    }
  });
};

const removeAllActivLinkClass = () => {
  specialisationLink.forEach((link) => {
    link.classList.remove('doctor-nav__specialisation-link--active');
  });
};

const hideDoctorNavBlock = () => {
  doctorSearchBlock.classList.add('doctor-search--active');
  doctorNav.classList.add('doctor-nav--hidden');
  doctorNav.classList.remove('doctor-nav--active');
  specialisationResultBlock.classList.add('doctor-nav__search-result--no-opacity');
  removeSearchBlockWidth();
  setTimeout(() => {
    specialisationResultBlock.classList.add('doctor-nav__search-result--hidden');
  }, 300);
  removeAllActivLinkClass();
};

const showDoctorNavBlock = () => {
  doctorSearchBlock.classList.remove('doctor-search--active');
  doctorNav.classList.remove('doctor-nav--hidden');
};

const setSearchBlockWidth = () => {
  const width = searchBlock.offsetWidth;
  searchBlock.style.width = width + 'px';
  setTimeout(() => {
    searchBlock.style = null;
    searchBlock.classList.add('doctor-nav__search-block--active');
  }, 0);
};

const removeSearchBlockWidth = () => {
  const width = searchBlock.offsetWidth;
  searchBlock.style.width = width + 'px';
  setTimeout(() => {
    searchBlock.style = null;
    searchBlock.classList.remove('doctor-nav__search-block--active');
  }, (window.matchMedia('(max-width:767px)') ? 200 : 600));
};

const setBlockState = () => {
  if (doctorSearchBlock) {
    specialisationLink.forEach((link) => {
      link.addEventListener('click', (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        const href = link.href;
        const title = link.dataset.title;
        specialisationResultLink.href = href;
        specialisationResultLink.innerHTML = title;
        if (link.classList.contains('doctor-nav__specialisation-link--active')) {
          specialisationResultBlock.classList.add('doctor-nav__search-result--no-opacity');
          doctorNav.classList.remove('doctor-nav--active');
          removeAllActivLinkClass();
          removeSearchBlockWidth();
        } else {
          document.querySelector('.doctor-nav__specialisation-page-link a').href = href;
          if (doctorNav.classList.contains(`doctor-nav--active`)) {
            removeAllActivLinkClass();
            specialisationResultBlock.classList.add(`doctor-nav__search-result--no-opacity`);
            specialisationResultBlock.classList.remove(`doctor-nav__search-result--hidden`);
            link.classList.add(`doctor-nav__specialisation-link--active`);
            setTimeout(() => {
              sortingResultLink(evt);
              specialisationResultBlock.classList.remove(`doctor-nav__search-result--no-opacity`);
            }, 300);
          } else {
            setSearchBlockWidth();
            removeAllActivLinkClass();
            setTimeout(() => {
              if(window.matchMedia('(max-width:767px)')) {
                const coordinates = searchWrapper.getBoundingClientRect();
                if(coordinates.bottom < 0) {
                  window.scrollBy({top: (coordinates.bottom - 65), left: 0, behavior: 'smooth'});
                }
              }
            }, 10);
            specialisationResultBlock.classList.add(`doctor-nav__search-result--no-opacity`);
            specialisationResultBlock.classList.remove(`doctor-nav__search-result--hidden`);
            doctorNav.classList.add(`doctor-nav--active`);
            link.classList.add(`doctor-nav__specialisation-link--active`);
            setTimeout(() => {
              sortingResultLink(evt);
              specialisationResultBlock.classList.remove(`doctor-nav__search-result--no-opacity`);
            }, (window.matchMedia('(max-width:767px)') ? 200 : 500));
          }
        }
      });
    });

    doctorSearchInput.addEventListener('focus', (evt) => {
      evt.preventDefault();
      hideDoctorNavBlock();
    });

    doctorNavBtn.addEventListener('click', (evt) => {
      evt.preventDefault();
      if (doctorNav.classList.contains('doctor-nav--hidden')) {
        showDoctorNavBlock();
      } else {
        hideDoctorNavBlock();
      }
    });

    mobileCloseDoctors.addEventListener('click', (evt) => {
      evt.preventDefault();
      hideDoctorNavBlock();
    });
  }

};

export { setBlockState, removeSearchBlockWidth };
