import {initAddedOption} from './input-select';

const showTimeBlockButtons = document.querySelectorAll('.form-time-block_button'),
  blockShowClass = 'form-time-block_inputs--show';

const initSelect = (block) => {
  if (block.isInitSelect !== true) {
    block.querySelectorAll('.custom-input__list').forEach((ul) => {
      for (let hour = 0; hour <= 23; hour++)
        ul.insertAdjacentHTML('beforeend', '<li>' + (hour < 10 ? '0' + hour : hour) + ':00</li>');
      ul.querySelectorAll('li').forEach(li => initAddedOption(li));
    });
    block.isInitSelect = true;
  }
};

const timeBlockAction = () => {
  showTimeBlockButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault();
      const block = event.currentTarget.nextElementSibling;
      if (block.classList.contains(blockShowClass)) {
        block.classList.remove(blockShowClass);
      } else {
        initSelect(block);
        block.classList.add(blockShowClass);
      }
    });
  });
};

export default timeBlockAction;
