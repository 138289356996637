const initShowMore = () => {
  const showMoreBlock = document.querySelectorAll(`.show-more-block`);
  if (!showMoreBlock.length) {
    return;
  }
  showMoreBlock.forEach((el) => {
    const showMoreBtn = el.querySelector(`.show-more-block__btn`);
    showMoreBtn.addEventListener(`click`, (e) => {
      e.preventDefault();
      el.classList.toggle(`show-more-block--active`);
    });
  });
};

export default initShowMore;
