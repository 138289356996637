const textBlocks = document.querySelectorAll(`.quote-slider .quote__inner`);
const showMoreBtns = document.querySelectorAll(`.quote-slider .show-more`);
const emptyModal = document.querySelector(`.empty-modal__content`);


const initShowMoreBtnOnTextBlock = () => {
  if (!textBlocks.length) {
    return;
  }

  showMoreBtns.forEach((el) => {
    el.addEventListener(`click`, () => {
      emptyModal.innerHTML = el.previousElementSibling.innerHTML;
    });
  });

  textBlocks.forEach((el) => {
    if (el.scrollHeight > el.clientHeight) {
      el.closest(`.quote`).classList.add(`quote--more`);
    } else {
      el.closest(`.quote`).classList.remove(`quote--more`);
    }
  });
};

export {initShowMoreBtnOnTextBlock};
