import {closeModal} from '../modals';


const select = document.querySelector('.js-select.faq__select input');
const faqList = document.querySelectorAll('.faq__accordeon .accordion__item');

const modalSelect = document.querySelector('.js-faq-filter-mobile .js-select input');
const modalSubmitButton = document.querySelector('.js-faq-filter-mobile .faq-filter-modal__button');

const classHidden = 'accordion__item--hidden';

const changeCategoryHandler = (chosenCategory) => {
  //if (chosenCategory === 'all') {
  if (chosenCategory === 'Все') {
    faqList.forEach((el) => {
      el.classList.remove(classHidden);
    });
  } else {
    faqList.forEach((el) => {
      if (el.dataset.category === chosenCategory) {
        el.classList.remove(classHidden);
      } else {
        el.classList.add(classHidden);
      }
    });
  }
};

const filterQuestions = () => {
  if (select && faqList.length) {
    select.addEventListener('change', (evt) => {
      /*const chosenCategory = select.dataset.category;*/
      const chosenCategory = evt.srcElement.value;
      changeCategoryHandler(chosenCategory);
    });
  }
  if (modalSelect && modalSubmitButton && faqList.length) {
    modalSubmitButton.addEventListener('click', (evt) => {
      evt.preventDefault();
      const chosenCategory = modalSelect.dataset.category;
      changeCategoryHandler(chosenCategory);
      const parent = modalSelect.closest(`.modal`);
      closeModal(parent, evt, false);
    });
  }
};

export default filterQuestions;
