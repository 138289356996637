import {freeModeSettings, initSlider} from '../swiper-settings';

const doctorStatisticsSlider = document.querySelector(`.doctor-card__statistics-slider`);

const setDoctorStatisticsSlider = () => {
  if (doctorStatisticsSlider) {
    const breakpoint = window.matchMedia(`(min-width:768px)`);
    let slider;

    const breakpointChecker = () => {
      if (breakpoint.matches === true) {
        if (slider) {
          slider.destroy(true, true);
        }
        return;
      } else if (breakpoint.matches === false) {
        enableSwiper();
      }
    };

    const enableSwiper = () => {
      slider = initSlider(doctorStatisticsSlider, freeModeSettings);
    };

    breakpoint.addListener(breakpointChecker);
    breakpointChecker();
  }
};

export default setDoctorStatisticsSlider;
