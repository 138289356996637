import {disableScrolling, enableScrolling} from './utils';

const openModal = (modal, evt, callback) => {
  modal.classList.add(`modal--active`);

  if (modal.classList.contains('js-individual-program-application-modal') || modal.classList.contains('js-individual-program-calculation-modal')) {
    const price = document.querySelector('.calculation-steps__item--fifth-step .calculation-steps__result-price').textContent;
    const services = document.querySelectorAll('.calculation-steps__item--fifth-step .calculation-steps__program-list li');
    let servicesStr = '';

    services.forEach((el, index) => {
      if (index === 0) {
        servicesStr += el.firstChild.textContent;
      } else {
        servicesStr += ', ' + el.firstChild.textContent;
      }
    });

    document.querySelector('[name=form_text_92]').value = price;
    document.querySelector('[name=form_text_96]').value = price;
    document.querySelector('[name=form_textarea_93]').value = servicesStr;
    document.querySelector('[name=form_textarea_97]').value = servicesStr;
  }

  if (callback) {
    callback(evt);
  }

  disableScrolling();
};

const closeModal = (modal, evt, callback) => {
  modal.classList.remove(`modal--active`);

  if (callback) {
    callback(evt);
  }

  setTimeout(enableScrolling, 300);
};

const onEscPress = (evt, modal, callback) => {
  const isEscKey = evt.key === `Escape` || evt.key === `Esc`;
  if (isEscKey && modal.classList.contains(`modal--active`)) {
    evt.preventDefault();
    closeModal(modal, evt, callback);
  }
};

const initializeModal = (modal, closeCallback) => {
  const overlay = modal.querySelector(`.modal__overlay`);
  const closeBtn = modal.querySelector(`.modal__close-btn`);
  const closeBtnMobileFilterReviews = modal.querySelector(`.js-modal-reviews-filter-close`);

  closeBtn.addEventListener(`click`, (evt) => {
    closeModal(modal, evt, closeCallback);
  });

  overlay.addEventListener(`click`, (evt) => {
    closeModal(modal, evt, closeCallback);
  });

  document.addEventListener(`keydown`, (evt) => {
    onEscPress(evt, modal, closeCallback);
  });

  if (closeBtnMobileFilterReviews) {
    closeBtnMobileFilterReviews.addEventListener(`click`, (evt) => {
      closeModal(modal, evt, closeCallback);
    });
  }
};

const setDependentValue = (modalWindow, element, code) => {
  const select = modalWindow.querySelector('[data-select="' + code + '"]');
  const values = element.dataset[code + 's'].split(",");
  if (select) {
    if (values.length === 1) {
      const option = select.querySelector('[data-' + code + 'id="' + values[0] + '"]');
      const clickEvent = new Event("click");
      if (option)
        option.dispatchEvent(clickEvent);
    } else {
      const input = select.parentNode.querySelector('input');
      if (input)
        input.value = "";
    }
  }
};

const activateModal = (modalWindow, modalBtns, openCallback, closeCallback) => {
  modalBtns.forEach((btn) => {
    btn.addEventListener('click', (evt) => {
      evt.preventDefault();

      const doctorId = btn.dataset?.doctorid;
      const specialityId = btn.dataset?.specialityId;

      if (doctorId) {
        const doctorElement = modalWindow.querySelector('[data-select="doctor"] [data-doctorid="' + doctorId + '"]');
        if (doctorElement) {
          const clickEvent = new Event('click');

          if (doctorElement.dataset.clinics !== 'undefined') {
            setDependentValue(modalWindow, doctorElement, 'clinic');
          }

          if (doctorElement.dataset.specializations !== 'undefined') {
            setDependentValue(modalWindow, doctorElement, 'specialization');
          }

          doctorElement.dispatchEvent(clickEvent);
        } else if (specialityId) {
          const specialityElement = modalWindow.querySelector('[data-select="specialization"] [data-specializationid="' + specialityId + '"]');

          if (specialityElement) {
            const clickEvent = new Event('click');
            specialityElement.dispatchEvent(clickEvent);
          }

        }
      }

      if (btn.classList.contains('js-make-appointment-btn') && typeof btn.dataset.service !== 'undefined') {
        const serviceName = btn.dataset.service;
        modalWindow.querySelector('[name=form_text_76]').value = serviceName;
      }

      openModal(modalWindow, evt, openCallback);
    });
  });

  initializeModal(modalWindow, closeCallback);
};

const dynamicModal = (modalWindow, btnClass, openCallback = false, closeCallback = false) => {
  document.addEventListener("click", (evt) => {
    if (evt.target && evt.target.classList.contains(btnClass)) {
      evt.preventDefault();
      openModal(modalWindow, evt, openCallback);
    }
  });

  initializeModal(modalWindow, closeCallback);
};

const showModalFromHash = () => {
  const matchHash = window.location.hash.substring(1).match(/form=([A-Za-z0-9_-]+)/gi);
  if (matchHash !== null) {
    const modalId = matchHash[0].split("=")[1].replace('-', '_');
    const input = document.getElementById("g-recaptcha-response-" + modalId);

    if (input !== null) {
      const modal = input.closest(".modal");
      if (modal !== null) {
        openModal(modal);
      }
    }
  }
};

export {activateModal, closeModal, openModal, initializeModal, dynamicModal, showModalFromHash};
