const moveToHeader = () => {
  const buttonUp = document.querySelectorAll(`.js-up`);
  const pageUp = document.querySelector(`body`);

  if (!document.querySelector('.footer__up-btn')) {
    return;
  }

  // eslint-disable-next-line no-undef
  const moveTo = new MoveTo({
    duration: 1500,
    easing: `easeOutQuart`
  });

  if (buttonUp.length) {
    buttonUp.forEach((el) => {
      const button = el;
      button.addEventListener(`click`, (evt) => {
        evt.preventDefault();
        moveTo.move(pageUp);
      });
    });
  }

  window.onload = function() {
      let windowHeight = isNaN(window.innerHeight) ? window.clientHeight : window.innerHeight;
      if (document.body.clientHeight <= (windowHeight + 37) ) {
          document.querySelector('.footer__up-btn').style.display = 'none';
      }
  }
};

export default moveToHeader;
