const setCookie = (name, value, day = 7) => {
  const date = new Date();
  date.setTime(date.getTime() + (day * 24 * 60 * 60 * 1000));
  const options = {
    path: '/',
    expires: date.toUTCString(),
  };
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }
  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  for (const optionKey in options) {
    if (Object.prototype.hasOwnProperty.call(options, optionKey)) {
      updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }
  }
  document.cookie = updatedCookie;
};

const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export {getCookie, setCookie};
