import {returnParent} from '../utils';

const tabsBlock = document.querySelectorAll(`.tabs-horizontal`);

const removeLinksActiveClass = (arr) => {
  arr.forEach((el) => {
    const link = el;
    link.classList.remove(`tabs-horizontal__tab--active`);
  });
};

const displayNoneAllProductContentBlocks = (arr) => {
  arr.forEach((el) => {
    const block = el;
    block.parentNode.classList.add(`hidden-no-pointer`);
  });
};

const hideAllProductContentBlocks = (arr) => {
  arr.forEach((el) => {
    const block = el;
    block.parentNode.classList.add(`no-opacity`);
  });
};

const showProductContentBlock = (block) => {
  block.parentNode.classList.remove(`no-opacity`);
};

const displayBlockProductContentBlock = (block) => {
  block.parentNode.classList.remove(`hidden-no-pointer`);
};

const tabLinksClickHandler = (evt, index) => {
  evt.preventDefault();
  const parent = returnParent(evt.target, `tabs-horizontal`);
  const linkArr = parent.querySelectorAll(`.tabs-horizontal__tab`);
  const blockArr = parent.querySelectorAll(`.tabs-horizontal__tab-item`);
  const intro = parent.querySelector(`.tabs-horizontal__intro`);
  if (!evt.target.classList.contains(`tabs-horizontal__tab--active`)) {
    intro.classList.add(`no-opacity`);
    removeLinksActiveClass(linkArr);
    evt.target.classList.add(`tabs-horizontal__tab--active`);
    hideAllProductContentBlocks(blockArr);
    setTimeout(() => {
      displayNoneAllProductContentBlocks(blockArr);
    }, 200);
    setTimeout(() => {
      intro.classList.add(`hidden-no-pointer`);
      displayBlockProductContentBlock(blockArr[index]);
    }, 200);
    setTimeout(() => {
      showProductContentBlock(blockArr[index]);
    }, 300);
  } else {
    evt.target.classList.remove(`tabs-horizontal__tab--active`);
    hideAllProductContentBlocks(blockArr);
    setTimeout(() => {
      displayNoneAllProductContentBlocks(blockArr);
      intro.classList.remove(`hidden-no-pointer`);
    }, 200);
    setTimeout(() => {
      intro.classList.remove(`no-opacity`);
    }, 300);
  }
};

const onClickTabHorizontalLinks = () => {
  if (tabsBlock.length) {
    tabsBlock.forEach((el) => {
      const block = el;
      const tabs = block.querySelectorAll(`.js-horizontal-tab`);
      tabs.forEach((link, i) => {
        const tab = link;
        const index = i;
        tab.addEventListener(`click`, (evt) => {
          tabLinksClickHandler(evt, index);
        });
      });
    });
  }
};

export default onClickTabHorizontalLinks;
