import {returnParent} from "./utils";

const tabsBlock = document.querySelectorAll(`.tabs-block`);
const tabs = document.querySelectorAll(`.tabs-block__tab`);
const breakpoint = window.matchMedia(`(max-width:767px)`);

if (tabs.length) {
  for (let i = 0; i < tabs.length; i++) {
    const counter = tabs[i].querySelector(`.tabs-block__counter`);
    if (!counter.innerText) {
      counter.style.display = `none`;
    }
  }
}

const removeLinksActiveClass = (arr) => {
  arr.forEach((el) => {
    const link = el;
    link.classList.remove(`tabs-block__tab--active`);
  });
};

const displayNoneAllProductContentBlocks = (arr) => {
  arr.forEach((el) => {
    const block = el;
    block.classList.add(`hidden`);
  });
};

const hideAllProductContentBlocks = (arr) => {
  arr.forEach((el) => {
    const block = el;
    block.classList.add(`no-opacity`);
  });
};

const showProductContentBlock = (block) => {
  block.classList.remove(`no-opacity`);
};

const displayBlockProductContentBlock = (block) => {
  block.classList.remove(`hidden`);
};

const setHash = (target) => {
  if (target.dataset.tab) {
    window.location.hash = "tab=" + target.dataset.tab;
  }
};

const tabLinksClickHandler = (evt, index) => {
  evt.preventDefault();
  const parent = returnParent(evt.target, `tabs-block`);
  const linkArr = parent.querySelectorAll(`.tabs-block__tab`);
  const blockArr = parent.querySelectorAll(`.tabs-block__tab-item`);
  if (!evt.target.classList.contains(`tabs-block__tab--active`)) {
    removeLinksActiveClass(linkArr);
    hideAllProductContentBlocks(blockArr);
    setTimeout(() => {
      displayNoneAllProductContentBlocks(blockArr);
      setTimeout(() => {
        displayBlockProductContentBlock(blockArr[index]);
      }, 200);
    }, 100);
    setTimeout(() => {
      showProductContentBlock(blockArr[index]);
      setHash(evt.target);
      parent.setAttribute("data-active-tab", evt.target.dataset.tab);
      evt.target.classList.add(`tabs-block__tab--active`);
    }, 300);
  }
};

const onClickTabLinks = () => {
  if (tabsBlock.length) {
    tabsBlock.forEach((el) => {
      const block = el;
      const blockTabs = block.querySelectorAll(`.js-tab`);
      blockTabs.forEach((link, i) => {
        const tab = link;
        const index = i;
        tab.addEventListener(`click`, (evt) => {
          tabLinksClickHandler(evt, index);
        });
      });
    });
  }
};

const initTabStateFromHash = () => {
  const matchHash = window.location.hash.substring(1).match(/tab=([A-Za-z0-9_-]+)/gi);
  if (matchHash !== null) {
    const activeTab = matchHash[0].split("=")[1];
    const tabLink = (breakpoint.matches === true) ? document.querySelector('.js-accordion-link[data-tab="' + activeTab + '"]') : document.querySelector('.js-tab[data-tab="' + activeTab + '"]');
    if (tabLink !== null) {
      tabLink.dispatchEvent(new Event('click'));
    }
  }
};

const initPregnancyTabs = () => {
  const tabs = document.querySelectorAll('.pregnancy-programs__block');
  const tabContents = document.querySelectorAll('.pregnancy-programs .accordion--highlight');

  function removeActiveClass() {
    tabs.forEach((tab) => {
      tab.classList.remove('active');
    });
    tabContents.forEach((tab) => {
      tab.classList.remove('active');
    });
  }

  tabs.forEach((tab) => {
    tab.addEventListener('click', function() {
      removeActiveClass();
      this.classList.add('active');
      document.querySelector(`.${this.dataset.show}`).classList.add('active');
    });
  });
}


const initGmsTabs = () => {
  const tabs = document.querySelectorAll('.gms-tabs__list a');
  const tabContents = document.querySelectorAll('.gms-tabs__item');

  function removeActiveClass() {
    tabs.forEach((tab) => {
      tab.classList.remove('active');
    });
    tabContents.forEach((tab) => {
      tab.classList.remove('active');
    });
  }

  tabs.forEach((tab) => {
    tab.addEventListener('click', function(e) {
      e.preventDefault();
      removeActiveClass();
      this.classList.add('active');
      document.querySelector(`${this.getAttribute('href')}`).classList.add('active');
    });
  });
};

export {onClickTabLinks, initTabStateFromHash, initGmsTabs, initPregnancyTabs, setHash};
