import radioChildIDAction from './radio-child-id';
import changeRequiredFields from './change-required-fields';

const form = document.querySelector('.js-submit-documents-form');
const patientCategorySelectInput = document.querySelector('.js-patient-category input');
const clinicLi = document.querySelectorAll('.js-clinic li');
const formClass = 'submit-documents__form';
const formModes = ['submit-documents__form--adult', 'submit-documents__form--child', 'submit-documents__form--en-adult', 'submit-documents__form--en-child', 'submit-documents__form--adult-ru-en', 'submit-documents__form--child-ru-en'];
const selectOptions = {
  'Гражданин РФ, взрослый': 'adult',
  'Гражданин РФ, ребенок': 'child',
  'Иностр.гражданин, взрослый': 'adult-ru-en',
  'Иностр.гражданин, ребенок': 'child-ru-en',
  'Foreign citizen adult': 'en-adult',
  'Foreign citizen child': 'en-child'
};

const filterPatient = (filter) => {
  let isFirst = true;
  document.querySelectorAll('.country_selector').forEach((li) => {
    if (filter.includes(li.dataset.type)) {
      li.hidden = false;
      if (isFirst === true) {
        const eventClick = new Event('click');
        li.dispatchEvent(eventClick);
        isFirst = false;
      }
    } else {
      li.hidden = true;
    }
  });
};

const setFormMode = () => {
  if (patientCategorySelectInput) {
    const newInputValue = patientCategorySelectInput.value;
    const newFormClass = `${formClass}--${selectOptions[newInputValue]}`;
    formModes.forEach((el) => {
      form.classList.remove(el);
    });
    changeRequiredFields(selectOptions[newInputValue]);
    form.classList.add(newFormClass);
    if (selectOptions[newInputValue] === 'child' || selectOptions[newInputValue] === 'child-ru-en') {
      radioChildIDAction();
    }
    /*patientCategorySelectInput.addEventListener('change', (evt) => {
      changeRequiredFields(selectOptions[patientCategorySelectInput.value]);
    });*/
  }

  if (clinicLi) {
    clinicLi.forEach((li) =>
      li.addEventListener('click', (evt) => filterPatient(evt.target.dataset.patient.split(',')))
    );
  }
};

export default setFormMode;
