import {disableScrolling, enableScrolling} from './utils';

const modalOpenBtn = document.querySelector(`.js-modal-open`);
const modalCloseBtn = document.querySelector(`.js-modal-close`);
const modal = document.querySelector(`.js-modal`);
const overlay = document.querySelector(`.modal-overlay`);

const closeModal = () => {
  overlay.classList.remove(`modal-overlay--active`);
  modal.classList.remove(`js-modal--active`);
  enableScrolling();
  // document.removeEventListener(`click`, overlayClickHandler);
};

const overlayClickHandler = () => {
  modalOpenBtn.classList.remove(`js-hide`);
  closeModal();
  document.removeEventListener(`click`, overlayClickHandler);
};


const openModal = () => {
  overlay.addEventListener(`click`, overlayClickHandler);
  disableScrolling();
  modal.classList.add(`js-modal--active`);
  overlay.classList.add(`modal-overlay--active`);

};

const onModalOpenBtnClick = (evt) => {
  evt.preventDefault();
  modalOpenBtn.classList.add(`js-hide`);
  openModal();
};

const onModalCloseBtnClick = (evt) => {
  evt.preventDefault();
  modalOpenBtn.classList.remove(`js-hide`);
  closeModal();
};


const modalAction = () => {
  if (modal) {
    modalOpenBtn.addEventListener(`click`, onModalOpenBtnClick);

    modalCloseBtn.addEventListener(`click`, onModalCloseBtnClick);
  }
};

const filterCloseBtn = document.querySelector('.js-modal-filter-close');

if (filterCloseBtn) {
  filterCloseBtn.addEventListener(`click`, onModalCloseBtnClick);
}

export default modalAction;
