const hasPlaceholderClass = `has-placeholder`;
const hiddenPlaceholderClass = `has-placeholder-hidden`;

const resetPlaceholderClasses = (el, input) => {
  if (input) {
    input.classList.remove(hiddenPlaceholderClass);
    input.classList.add(hasPlaceholderClass);
    /*
    if (el.required) {
      input.classList.add(hasPlaceholderClass);
    } else {
      input.classList.remove(hasPlaceholderClass);
    }
    */
  }
};

const togglePlaceholderVisibility = (el, input) => {
  if (input) {
    if (el.value !== ``) {
      input.classList.add(hiddenPlaceholderClass);
    }
    if (el.value === ``) {
      input.classList.remove(hiddenPlaceholderClass);
    }
  }
};

const onInputChange = (evt) => {
  const element = evt.target;
  const input = element.closest(`.custom-input`);
  const textarea = element.closest(`.custom-textarea`);
  togglePlaceholderVisibility(element, input);
  togglePlaceholderVisibility(element, textarea);
};

const customPlaceholderAction = () => {
  const allInputs = document.querySelectorAll(`.custom-input input, .custom-textarea textarea`);
  if (allInputs.length) {
    allInputs.forEach((el) => {
      const input = el.closest(`.custom-input`);
      const textarea = el.closest(`.custom-textarea`);
      resetPlaceholderClasses(el, input);
      resetPlaceholderClasses(el, textarea);
      el.removeEventListener(`input`, onInputChange);
      el.removeEventListener(`change`, onInputChange);
      //if (el.required) {
        setTimeout(() => {
          togglePlaceholderVisibility(el, input);
        }, 100);
        el.addEventListener(`input`, onInputChange);
        el.addEventListener(`change`, onInputChange);
      //}
    });
  }
};

export default customPlaceholderAction;
