import {returnParent} from './utils';
import {setHash} from './tab';

const accordionTabs = document.querySelectorAll('.js-accordion-link');

// eslint-disable-next-line no-undef
const moveTo = new MoveTo({
  duration: 1000,
  easing: 'easeOutQuart',
});

const closeAllAccordion = (evt) => {
  const parent = returnParent(evt.target, 'js-accordion');
  const contentWrappers = parent.querySelectorAll('.js-accordion-wrapper');
  const accordionItemWrappers = parent.querySelectorAll('.tabs-block__tabs-wrapper');
  const accordionHorizontalItemWrappers = parent.querySelectorAll('.tabs-horizontal__tab-wrapper');
  accordionHorizontalItemWrappers.forEach((el) => {
    el.classList.remove('tabs-block__tabs-wrapper--active');
  });
  accordionItemWrappers.forEach((el) => {
    el.classList.remove('tabs-block__tabs-wrapper--active');
  });
  contentWrappers.forEach((el) => {
    el.style.maxHeight = null;
  });
};

const accordion = () => {
  if (accordionTabs) {
    accordionTabs.forEach((el) => {
      const link = el;
      link.addEventListener('click', (evt) => {
        evt.preventDefault();
        const contentWrapper = link.nextElementSibling;
        const maxHeight = contentWrapper.style.maxHeight;
        const accordionItemWrapper = link.parentNode;
        const target = evt.target;
        const parent = returnParent(target, 'js-accordion');
        if (!maxHeight) {
          closeAllAccordion(evt);
          accordionItemWrapper.classList.add('tabs-block__tabs-wrapper--active');
          contentWrapper.style.maxHeight = contentWrapper.scrollHeight + 'px';
          if(target.dataset.tab)
            setHash(target);
          setTimeout(() => {
            contentWrapper.style.maxHeight = 100 + '%';
            moveTo.move(link);
          }, 350);
        } else {
          closeAllAccordion(evt);
          moveTo.move(parent);
        }
      });
    });
  }
};

export default accordion;
