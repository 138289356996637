let root = document.querySelector(`:root`);

const setModalHeight = () => {
  const getCurrentviewportHeight = () => {
    const intViewportHeight = `${window.innerHeight}px`;
    root.style.setProperty(`--window-inner-height`, intViewportHeight);
  };
  getCurrentviewportHeight();

  window.addEventListener(`resize`, getCurrentviewportHeight);
  window.addEventListener(`scroll`, getCurrentviewportHeight);
};

export default setModalHeight;

