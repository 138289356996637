// свайпер на страничке блога
const sliderWraper = document.querySelectorAll(`.swiper-wrapper`);
const sliderContainer = document.querySelectorAll(`.swiper-container`);
const navigatorCaruselBlock = document.querySelector(`.js-navigator-carusel-mod`);
const onlyMovileCarouselBlocks = document.querySelectorAll(`.js-only-mobile-carousel-mod`);

let swiperList = [];

const navigationModeSettings = {
  updateOnWindowResize: true,
  navigation: {
    nextEl: `.swiper-button-next`,
    prevEl: `.swiper-button-prev`,
  }
};

const carouselModeSettings = {
  slidesPerView: `auto`,
  slidesOffsetBefore: 20,
  slidesOffsetAfter: 20,
  updateOnWindowResize: true,
  spaceBetween: 8
};


const initSlider = (el, settings) => {
  // eslint-disable-next-line no-undef
  const swiper = new Swiper(el, settings);
  swiperList.push(swiper);
};
const breakpoint = window.matchMedia(`(max-width:767px)`);

const activateSwiper = () => {
  if (sliderContainer.length) {
    Array.prototype.forEach.call(sliderWraper, (el) => {
      const wrapper = el;
      if (wrapper.parentNode.classList.contains(`hidden`)) {
        wrapper.parentNode.classList.remove(`hidden`);
        setTimeout(() => {
          wrapper.parentNode.classList.add(`hidden`);
        }, 100);
      }
    });

    const breakpointChecker = () => {
      if (breakpoint.matches === true) {
        if (navigatorCaruselBlock) {
          initSlider(navigatorCaruselBlock, carouselModeSettings);
        }
        if (onlyMovileCarouselBlocks) {
          for (let i = 0; i < onlyMovileCarouselBlocks.length; i++) {
            onlyMovileCarouselBlocks[i].firstElementChild.classList.add(`swiper-wrapper`);
            initSlider(onlyMovileCarouselBlocks[i], carouselModeSettings);
          }
        }
        return;

      } else if (breakpoint.matches === false) {
        if (navigatorCaruselBlock) {
          initSlider(navigatorCaruselBlock, navigationModeSettings);
        }
        if (onlyMovileCarouselBlocks) {
          for (let i = 0; i < onlyMovileCarouselBlocks.length; i++) {
            onlyMovileCarouselBlocks[i].firstElementChild.classList.remove(`swiper-wrapper`);
          }
        }
      }
    };

    breakpoint.addListener(breakpointChecker);
    breakpointChecker();
  }
};

// const checkmobileWidth = () => {
//   if (breakpoint.matches === true) {
//     return true;
//   }
//   return false;
// };

// let isMobileWidth = checkmobileWidth();

// const resizeSwiper = () => {
//   if (checkmobileWidth() !== isMobileWidth) {
//     isMobileWidth = !isMobileWidth;
//     swiperList.forEach(function (swiper) {
//       swiper.destroy();
//     });
//     swiperList = [];
//     activateSwiper();
//   }
// };

// window.addEventListener(`resize`, resizeSwiper);

export default activateSwiper;
