import {closeModal, initializeModal, openModal} from "../modals";
import {RECAPTCHA_PUBLIC_KEY} from "./recaptha";
import {reachGoal} from './../analytics';

const modals = document.querySelectorAll(`.download-modal`);
const warningVisibleClass = `download-modal__warning--visible`;
const errorMessages = {
  wrongType: 'Неверный тип файа, допустимые форматы: .doc, .docx',
  tooBig: 'Слишком большой файл, максимальный размер 15Мб',
};

const inputFileAction = () => {
  if (modals.length) {
    modals.forEach((el) => {
      const form = el.querySelector(`.download-modal__form-wrapper form`);
      const submitButton = el.querySelector(`.download-modal__form-wrapper form button[type="submit"]`);
      const wrapper = el.closest(`.modal--download`);
      const warning = el.querySelector(`.download-modal__warning`);
      const select = el.querySelector(`.js-select`);
      // eslint-disable-next-line no-undef
      const myDropZone = new Dropzone(form, {
        paramName: `form_file_88`,
        createImageThumbnails: false,
        acceptedFiles: `.doc, .docx`,
        dictFallbackMessage: `Пожалуйста, воспользуйтесь формой ниже`,
        autoProcessQueue: false,

        init: function() {
          const myDropzone = this;

          submitButton.addEventListener('click', function(e) {
            e.preventDefault();
            myDropzone.processQueue();
          });
          this.on('sending', function(file, xhr, formData) {
            if (select) {
              formData.append(`chosenVacancy`, document.getElementById(`vacancy`).value);
            }
          });
          this.on('success', function(file, xhr, formData) {
            const resumeModal = document.querySelector('.js-download-modal');
            closeModal(resumeModal, 'empty', false);
            const successModal = document.querySelector(`.js-success-modal`);
            const successContentBlock = document.querySelector(`.js-success-modal .success-modal__content`);
            successContentBlock.innerHTML = ``;

            let successText = '<h2>Резюме успешно отправлено</h2>';
            if(document.querySelectorAll(`.site-id-s2`).length)
              successText = '<h2>CV sent successfully</h2>';

            let id = 'g-recaptcha-response-resume';
            let initParam = 'resume';
            grecaptcha.execute(RECAPTCHA_PUBLIC_KEY, {action: initParam}).then(function(token) {
              document.getElementById(id).value = token;
            });

            setTimeout(() => {
              successContentBlock.insertAdjacentHTML(`afterbegin`, successText);
              openModal(successModal, 'empty', false);
              initializeModal(successModal, false);
              reachGoal(form.dataset.goal);
            }, 600);
          });
        },
      });

      myDropZone.on(`addedfile`, function(file) {
        showWarning(false);
        document.removeEventListener(`dragend`, onDocumentDragend);
        document.addEventListener(`dragover`, onDocumentDragover);
        el.classList.remove(`drag-start`);
        if (wrapper) {
          wrapper.classList.remove(`drag`);
        }
        centerMessage(el, false);

        window.file = file;

        const onAddFile = () => {
          if (file.accepted && (file.size <= 1.5e+7)) {
            el.classList.add(`drop`);
            el.querySelector(`.download-modal__file-name`).textContent = file.upload.filename;
            el.querySelector(`.download-modal__delete-btn`).addEventListener(`click`, function(evt) {
              evt.preventDefault();
              myDropZone.removeFile(file);
              el.classList.remove(`drop`);
              centerMessage(el, false);
              submitButton.setAttribute(`disabled`, `disabled`);
            });
            submitButton.removeAttribute(`disabled`);
          } else {
            showWarning(true, file);
          }
        };



        setTimeout(onAddFile, 100);
      });

      const showWarning = (show, file) => {
        if (show) {
          if (!file.accepted && (file.size <= 1.5e+7)) {
            warning.textContent = errorMessages.wrongType;
          } else if (file.accepted && !(file.size <= 1.5e+7)) {
            warning.textContent = errorMessages.tooBig;
          } else if (!file.accepted && !(file.size <= 1.5e+7)) {
            warning.textContent = `${errorMessages.wrongType}. ${errorMessages.tooBig}.`;
          }
          warning.classList.add(warningVisibleClass);
        } else {
          warning.classList.remove(warningVisibleClass);
        }
      };

      const onDocumentDragend = function() {
        document.removeEventListener(`dragend`, onDocumentDragend);
        document.addEventListener(`dragover`, onDocumentDragover);
        el.classList.remove(`drag-start`);
        if (wrapper) {
          wrapper.classList.remove(`drag`);
          centerMessage(el, false);
        }
      };

      const onDocumentDragover = function() {
        el.classList.add(`drag-start`);
        if (wrapper) {
          wrapper.classList.add(`drag`);
        }
        centerMessage(el, true);
        document.removeEventListener(`dragover`, onDocumentDragover);
        document.addEventListener(`dragend`, onDocumentDragend);
      };
      document.addEventListener(`dragover`, onDocumentDragover);
    });
  }
};

const centerMessage = (el, center) => {
  const message = el.querySelector(`.dz-message`);
  const wrapper = el.querySelector(`.download-modal__content`);
  if (message && wrapper) {
    if (center) {
      message.style.top = (-(message.offsetTop - wrapper.offsetHeight / 2) - message.offsetHeight / 2) + `px`;
    } else {
      message.style.top = `0px`;
    }
  }
};

export default inputFileAction;
