const asideMediaBlock = document.querySelector(`.about-media__aside`);
const mainMediaBlock = document.querySelector(`.main-content`);
const innerMediaBlock = document.querySelector(`.media`);

const setAsideOffset = () => {
  if (asideMediaBlock && mainMediaBlock && innerMediaBlock) {
    let offsetTop = (innerMediaBlock.offsetTop - mainMediaBlock.offsetTop) + `px`;
    asideMediaBlock.style.setProperty(`--aside-media-top-offset`, offsetTop);
  }
};

window.addEventListener(`resize`, setAsideOffset);

export default setAsideOffset;
