import {disableScrolling, enableScrolling} from '../utils';
import {renderSearchResult} from './search-form';

const searchOpenBtns = document.querySelectorAll(`.js-search-open`);
const searchCloseBtn = document.querySelector(`.js-search-close`);
const searchModal = document.querySelector(`.search-modal`);
const searchModalForm = document.querySelector(`.search-modal form`);
const searchModalNav = document.querySelector(`.search-modal__nav`);
const searchModalLinks = document.querySelectorAll(`.search-modal a`);
const searchModalResultBlock = document.querySelector(`.search-result`);
const dataSearch = require(`../../data/data-search.json`);

const openSearchModal = () => {
  searchModal.classList.add(`search-modal--display`);
  disableScrolling();
  renderSearchResult(searchModal, dataSearch, true);
  setTimeout(() => {
    searchModal.classList.remove(`search-modal--animate`);
  });
};

const closeSearchModal = () => {
  searchModal.classList.add(`search-modal--animate`);
  searchModalForm.reset();
  renderSearchResult(searchModal, dataSearch, false);
  setTimeout(() => {
    searchModal.classList.remove(`search-modal--display`);
    searchModalNav.classList.remove(`hide-block--hidden`);
    searchModalNav.classList.remove(`hide-block--no-opacity`);
    searchModalResultBlock.classList.add(`search-result--no-opacity`);
    searchModalResultBlock.classList.add(`search-result--hidden`);
    enableScrolling();
  }, 600);
};

const onSearchOpenBtnClick = (evt) => {
  evt.preventDefault();
  openSearchModal();
  document.addEventListener(`keydown`, onEscKeyDown);
};

const onSearchCloseBtnClick = (evt) => {
  evt.preventDefault();
  closeSearchModal();
};

const onEscKeyDown = (evt) => {
  const isEscKey = evt.key === `Escape` || evt.key === `Esc`;
  if (isEscKey) {
    closeSearchModal();
    document.removeEventListener(`keydown`, onEscKeyDown);
  }
};

const searchModalAction = () => {
  if (searchModalLinks.length) {
    searchModalLinks.forEach((el) => {
      const link = el;
      link.addEventListener(`click`, closeSearchModal);
    });
  }
  if (searchOpenBtns.length && searchModal) {
    searchModal.classList.add(`search-modal--animate`);
    searchOpenBtns.forEach((el) => {
      const btn = el;
      btn.addEventListener(`click`, onSearchOpenBtnClick);
    });
    searchCloseBtn.addEventListener(`click`, onSearchCloseBtnClick);
  }
};

export default searchModalAction;
