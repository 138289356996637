const departmentCodeInputs = document.querySelectorAll('.js-department-code input');
const instanceMask = [];

const maskDepartmentCode = () => {
  if (departmentCodeInputs.length) {
    departmentCodeInputs.forEach((input) => {
      instanceMask.push(new IMask(input, { mask: '000-000'}));
    });
  }
};

const unmaskDepartmentCode = () => {
  while(instanceMask.length){
    instanceMask.pop().destroy();
  }
};

export {maskDepartmentCode, unmaskDepartmentCode};
