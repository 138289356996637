import {storiesModeSettings, initSlider, certificateSettings, checkupModeSettings, doctorModeSettings, moscowClinicsModeSettings, spbClinicsModeSettings, womenServiceModeSettings, menServiceModeSettings, childrenServiceModeSettings} from '../swiper-settings';
import {returnParent} from '../utils';

const sliderWraper = document.querySelectorAll(`.swiper-wrapper`);
const womenSliderBlock = document.querySelector(`.main-services__women-slider`);
const menSliderBlock = document.querySelector(`.main-services__men-slider`);
const childrenSliderBlock = document.querySelector(`.main-services__children-slider`);
const doctorsSliderBlock = document.querySelector(`.main-doctors__slider`);
const storiesSliderBlock = document.querySelector(`.main-stories__slider`);
const moscowClinickSliderBlock = document.querySelector(`.main-clinics__moscow-slider`);
const spbClinickSliderBlock = document.querySelector(`.main-clinics__spb-slider`);
const certificateSliderBlock = document.querySelector(`.certificate-slider`);
const checkupSliderBlock = document.querySelector(`.services-cards-slider__slider-wrap--checkup`);

const initMainPageSliders = () => {
  if (sliderWraper.length) {
    sliderWraper.forEach((el) => {
      const wrapper = el;
      const parent = returnParent(wrapper, `tabs-block__tab-item`);
      if (parent && parent.classList.contains(`hidden`)) {
        parent.classList.remove(`hidden`);
        setTimeout(() => {
          parent.classList.add(`hidden`);
        }, 100);
      }
    });
    if (womenSliderBlock) {
      initSlider(womenSliderBlock, womenServiceModeSettings);
    }
    if (menSliderBlock) {
      initSlider(menSliderBlock, menServiceModeSettings);
    }
    if (childrenSliderBlock) {
      initSlider(childrenSliderBlock, childrenServiceModeSettings);
    }
    if (storiesSliderBlock) {
      initSlider(storiesSliderBlock, storiesModeSettings);
    }
    if (doctorsSliderBlock) {
      initSlider(doctorsSliderBlock, doctorModeSettings);
    }
    if (moscowClinickSliderBlock) {
      initSlider(moscowClinickSliderBlock, moscowClinicsModeSettings);
    }
    if (spbClinickSliderBlock) {
      initSlider(spbClinickSliderBlock, spbClinicsModeSettings);
    }
    if (certificateSliderBlock) {
      initSlider(certificateSliderBlock, certificateSettings);
    }
    if (checkupSliderBlock) {
      initSlider(checkupSliderBlock, checkupModeSettings);
    }
  }
};

export default initMainPageSliders;

