const lastParagraphs = document.querySelectorAll(`.quotes-slider__item p:last-of-type`);
const authorInfo = document.querySelectorAll(`.quotes-slider__item .author-info`);
const additionalMargin = 15;

const giveSpaceToAuthorInfo = () => {
  if (lastParagraphs.length && authorInfo.length) {
    lastParagraphs.forEach((el, i) => {
      if (authorInfo[i]) {
        if (window.matchMedia(`(max-width:1023px)`)) {
          let newMargin = authorInfo[i].offsetHeight + additionalMargin;
          el.style.marginBottom = `${newMargin}px`;
        } else {
          el.style.marginBottom = ``;
        }
      }
    });
  }
};

window.addEventListener(`resize`, giveSpaceToAuthorInfo);

export default giveSpaceToAuthorInfo;
