import {setCookie} from "./../../util/cookie";

const headerInfoBlock = document.querySelector('.header-info');

const closeHeaderInfo = () => {
  if(headerInfoBlock) {

    headerInfoBlock.querySelector('.btn').addEventListener('click', (event) => {
      event.preventDefault();
      const bannerId = +event.currentTarget.dataset.bannerId;

      if(bannerId > 0)
        setCookie('banner-hide-' + bannerId, 1, 1);

      headerInfoBlock.remove();
    });
  }
};

export default closeHeaderInfo;
