const clinicSliders = document.querySelectorAll(`.about-clinic__slider.swiper-container`);
const initClinicSlider = () => {
  if (clinicSliders.length) {
    clinicSliders.forEach((el) => {
      // eslint-disable-next-line no-undef
      const newSlider = new Swiper(el, {
        updateOnWindowResize: true,
        slidesPerView: 4,
        sliderPerGroup: 1,
        spaceBetween: 8,
        initialSlide: 0,
        shortSwipes: false,
        longSwipesMs: 100,
        speed: 1000,
        navigation: {
          nextEl: el.querySelector(`.swiper-button-next`),
          prevEl: el.querySelector(`.swiper-button-prev`),
        },

        breakpoints: {
          1366: {
            slidesPerView: 4,
            sliderPerGroup: 1,
          },
          1024: {
            slidesPerView: 3,
            sliderPerGroup: 1,
            spaceBetween: 6,
          },
          768: {
            slidesPerView: 2,
            sliderPerGroup: 1,
          },
          320: {
            slidesPerView: 1.10,
            sliderPerGroup: 1,
            spaceBetween: 8,
          }
        }
      });
    });
  }
};

export default initClinicSlider;
