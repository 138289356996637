const HIDDEN_BLOCK_CLASS = `visually-hidden`;
const videos = document.querySelectorAll(`.js-video-clip`);

const playVideo = (currentVideoElement) => {
  currentVideoElement.video.contentWindow.postMessage(`{"event":"command","func":"playVideo","args":""}`, `*`);
};

const stopAllVideos = (allVideos, currentVideo) => {
  allVideos.forEach((el) => {
    if (el !== currentVideo.video) {
      const video = el;
      const parent = video.parentElement;
      const playButton = parent.querySelector(`.btn--video`);
      const imageBack = parent.querySelector(`.js-bg-img`);
      const btnWrap = parent.querySelector(`.js-btn-wrap`); // добавить всем кнопкам врапперы с классом js-btn-wrap и вернуть поиск элемента оп ним
      const overlay = parent.querySelector(`.video-overlay`);
      let src = el.src;
      const currentVideoElementToStop = {video, playButton, imageBack, btnWrap, overlay, src};
      stopVideo(currentVideoElementToStop);
    }
  });
};

const stopVideo = (currentVideoElement) => {
  currentVideoElement.video.src = ``;
  currentVideoElement.btnWrap.classList.remove(HIDDEN_BLOCK_CLASS);
  currentVideoElement.imageBack.classList.remove(HIDDEN_BLOCK_CLASS);
  setTimeout(() => {
    currentVideoElement.video.src = currentVideoElement.src;
  }, 100);
};

const onVideoClick = (currentVideoElement) => {
  // stopVideo(currentVideoElement);
  console.log('hi');

  currentVideoElement.overlay.removeEventListener(`click`, onVideoClick);
  currentVideoElement.overlay.classList.add(HIDDEN_BLOCK_CLASS);
};

const onPlayBtnClick = (currentVideoElement) => {
  stopAllVideos(videos, currentVideoElement);
  playVideo(currentVideoElement);
  setTimeout(() => {
    currentVideoElement.overlay.classList.remove(HIDDEN_BLOCK_CLASS);
    currentVideoElement.btnWrap.classList.add(HIDDEN_BLOCK_CLASS);
    currentVideoElement.imageBack.classList.add(HIDDEN_BLOCK_CLASS);
  }, 300);

  currentVideoElement.overlay.addEventListener(`click`, function () {
    onVideoClick(currentVideoElement);
  });
};

const setYtVideo = () => {
  if (videos.length) {
    videos.forEach((el) => {
      const video = el;
      const parent = video.parentElement;
      const playButton = parent.querySelector(`.btn--video`);
      const imageBack = parent.querySelector(`.js-bg-img`);
      const btnWrap = parent.querySelector(`.js-btn-wrap`); // добавить всем кнопкам врапперы с классом js-btn-wrap и вернуть поиск элемента оп ним
      const overlay = parent.querySelector(`.video-overlay`);
      let src = el.src;
      const currentVideoElement = {video, playButton, imageBack, btnWrap, overlay, src};
      playButton.addEventListener(`click`, () => {
        onPlayBtnClick(currentVideoElement);
      });
    });
  }
};


export default setYtVideo;
