const showMoreListButtons = document.querySelectorAll(`.ordered-list-block .show-more`);
const showMoreSliderButtons = document.querySelectorAll(`.quotes-slider .show-more`);
const showMoreNewsSliderButtons = document.querySelectorAll(`.news-slider__slider-list .show-more`);
const showMoreReviewButtons = document.querySelectorAll(`.review .show-more`);
const showMoreContainerClass = `.js-show-more-container`;
const showClass = `open`;
const smallClass = `small`;

let buttonInnerTextOpen = `Свернуть`;
if (document.querySelectorAll(`.site-id-s2`).length)
  buttonInnerTextOpen = 'Collapse';

const toggleContent = (button, initialInnerText) => {
  const container = button.closest(showMoreContainerClass);
  if (container) {
    if (container.classList.contains(showClass)) {
      container.classList.remove(showClass);
      button.querySelector(`span`).innerText = initialInnerText;
    } else {
      container.classList.add(showClass);
      button.querySelector(`span`).innerText = buttonInnerTextOpen;
    }
  }
};

const markSmallRewiews = (button) => {
  const container = button.closest(showMoreContainerClass);
  if (container && container.querySelectorAll('p').length === 1 && container.querySelector('p').scrollHeight <= 174) {
    container.classList.add(smallClass);
  } else {
    container.classList.remove(smallClass);
  }
};


const setEventListener = (el) => {
  const button = el;
  //const initialInnerText = button.querySelector(`span`).innerText;
  button.addEventListener(`click`, () => {
    toggleContent(button, button.querySelector(`span`).innerText);
  });
};

const showMore = () => {
  if (showMoreListButtons.length) {
    showMoreListButtons.forEach((el) => {
      setEventListener(el);
    });
  }
  if (showMoreSliderButtons.length) {
    showMoreSliderButtons.forEach((el) => {
      setEventListener(el);
    });
  }
  if (showMoreNewsSliderButtons.length) {
    showMoreNewsSliderButtons.forEach((el) => {
      setEventListener(el);
    });
  }
  if (showMoreReviewButtons.length) {
    showMoreReviewButtons.forEach((el) => {
      markSmallRewiews(el);
      window.addEventListener(`resize`, () => {
        markSmallRewiews(el);
      });
      setEventListener(el);
    });
  }
};

export {showMore, toggleContent, setEventListener};

