import {renderSearchResult} from '../page-main/search-form';

const searchServicesBlock = document.querySelector('.services__search-block'),
  searchServiceResult = searchServicesBlock?.querySelector('.search-result') || {},
  searchServiceInput = searchServicesBlock?.querySelector('.search-services') || {};


let isSetEventClose = false;

const hiddenSearchResult = () => {
  searchServiceResult.classList.add('search-result--hidden');
  searchServiceResult.innerHTML = '';
  searchServiceInput.value = '';
  uninstallCloseEvent();
};

const hiddenSearchResultByEsc = (event) => {
  if (event.code === 'Escape')
    hiddenSearchResult();
};

const hiddenSearchResultByClick = (event) => {
  if (event.target.closest('.services__search-block') === null)
    hiddenSearchResult();
};

const installCloseEvent = () => {
  if (!isSetEventClose) {
    document.addEventListener('keydown', hiddenSearchResultByEsc);
    document.addEventListener('click', hiddenSearchResultByClick);
    isSetEventClose = true;
  }
};

const uninstallCloseEvent = () => {
  document.removeEventListener('keydown', hiddenSearchResultByEsc);
  document.removeEventListener('click', hiddenSearchResultByClick);
  isSetEventClose = false;
};

const initServicesSearch = () => {
  if (searchServicesBlock) {
    renderSearchResult(searchServicesBlock, [], true);
    document.addEventListener('searchShowResult', installCloseEvent);
  }
};

export default initServicesSearch;
