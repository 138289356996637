let loadContainer = document.querySelectorAll('[data-js-load-more-container]');
let selectorBtn = '[data-js-load-more-btn]';
let waitResponse = false;

const loadContent = (link, container) => {
  fetch(link, {
    headers: {
      'bx-ajax': 'Y',
      'gms-load-more': 'Y'
    }
  }).then((response) => response.text())
    .then((result) => {
      container.insertAdjacentHTML('beforeend', result);
      waitResponse = false;
    });
};

const initLoadMore = () => {
  loadContainer.forEach((container) => {
    container.addEventListener('click', (event) => {
      if (event.target.matches(selectorBtn)) {
        event.preventDefault();

        let nextLink = event.target.dataset.next;
        let removeElement = event.target.dataset?.removeParent ? event.target.parentNode : event.target;

        if (nextLink && waitResponse !== true) {
          waitResponse = true;
          removeElement.remove();
          loadContent(nextLink, container);
        }
      }
    });
  });
};

export default initLoadMore;
