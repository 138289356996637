const inputs = document.querySelectorAll(`[name^=g-recaptcha-response-]`);
const tokenLifeTime = 100;
const RECAPTCHA_PUBLIC_KEY = '6LcRG9IaAAAAALUAWLk3IsTr5bjtnOltcGYj_371';
let timeoutId = null;
let promiseLoadRecaptha = null;

const reloadToken = () => {

  inputs.forEach(item => {
    let action = item.name.split("g-recaptcha-response-")[1];

    grecaptcha.execute(RECAPTCHA_PUBLIC_KEY, {action: action}).then(token => {
      item.value = token;
    });

  });
};

const recaptha = () => {
  if (typeof(grecaptcha) === 'undefined') return;

  clearTimeout(timeoutId);

  timeoutId = setTimeout(() => {
      recaptha();
    },
    tokenLifeTime * 1000);

  grecaptcha.ready(() => {
    reloadToken();
  });
};

function loadScript() {
  return new Promise(function(resolve, reject) {
    let script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + RECAPTCHA_PUBLIC_KEY;
    script.async = 1;

    script.onload = () => resolve(true);
    script.onerror = () => reject(new Error('Loading script ReCaptha'));
    document.head.append(script);
  });
}

const load = function (event) {
  if(event.target.tagName.toLowerCase() === "input")
  {
    promiseLoadRecaptha = loadScript();
    promiseLoadRecaptha.then(recaptha);
    document.removeEventListener("click", load);
  }
};

const initRecaptha = () => {
  document.addEventListener("click",  load);
};

export {initRecaptha, RECAPTCHA_PUBLIC_KEY};
