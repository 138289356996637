const header = document.querySelector('.header');
const wrapper = document.querySelector('.wrapper');
const pageIntro = document.querySelector('.page-intro');
const mainIntro = document.querySelector('.main-intro');
const headerInfoBlock = document.querySelector('.header-info');

const initFixHeader = () => {
  if (header) {
    const headerHeight = header.getBoundingClientRect().height;
    window.addEventListener('scroll', () => {
      const scrollPosition = window.pageYOffset;
      if (scrollPosition > 0) {
        header.classList.add('header--fixed');
        header.classList.add('active');
        wrapper.style.paddingTop = `${headerHeight}px`;
      } else {
        header.classList.remove('header--fixed');
        header.classList.remove('active');
        wrapper.style.paddingTop = 0;
      }
    });
  }
};

export {initFixHeader};
