import filterSpecialisation from './letter-sort';
import setSpecialisationCounter from './set-specialisation-list-state';
import renderDoctorsResult from './render-doctors-result';
import { removeSearchBlockWidth } from './page-doctor-action';
import searchDoctors from './search-doctors.json';
import searchSpecializationAge from './search-specialization-age.json';

const doctorsForm = document.querySelector('.doctor-nav__search-form form');
const doctorsSpecialisationList = document.querySelector('.doctor-nav__specialisation-list');
const doctorsSpecialisationLink = document.querySelectorAll('.doctor-nav__specialisation-list a');
const doctorsClinicInput = document.querySelector('.doctor-nav__search-form .custom-input__wrapper input');
const adultCheckbox = document.querySelector('.doctor-nav__search-form .custom-input--adult input');
const childCheckbox = document.querySelector('.doctor-nav__search-form .custom-input--child input');
const doctorNav = document.querySelector('.doctor-nav');
const specialisationResultBlock = document.querySelector('.doctor-nav__search-result');

const dataSearch = window.searchDoctors || JSON.stringify(searchDoctors);
const data = JSON.parse(dataSearch);

const dataSpecializationAge = window.searchSpecializationAge || JSON.stringify(searchSpecializationAge);
const data2 = JSON.parse(dataSpecializationAge);

const filteringClinic = (el) => {
  if (!doctorsClinicInput.dataset.clinic || doctorsClinicInput.dataset.clinic.toLowerCase() === 'all') {
    return el;
  }

  return el.clinic === doctorsClinicInput.dataset.clinic;
};

const filteringAge = (el) => {
  if (adultCheckbox.checked === childCheckbox.checked) {
    return el;
  }

  if (adultCheckbox.checked) {
    doctorsSpecialisationLink.forEach((e) => {
      const age = data2.find((specAge) => specAge.HASH_ID === e.dataset.specialisation).AGE;
      if (age.includes('adult') || age.length === 0) {
        e.parentNode.style.display = 'flex';
      } else {
        e.parentNode.style.display = 'none';
      }
    });
    return el.adult;
  }
  if (childCheckbox.checked) {
    doctorsSpecialisationLink.forEach((e) => {
      const age = data2.find((specAge) => specAge.HASH_ID === e.dataset.specialisation).AGE;
      if (age.includes('child') || age.length === 0) {
        e.parentNode.style.display = 'flex';
      } else {
        e.parentNode.style.display = 'none';
      }
    });
    return el.child;
  }
};

const getFilteringData = (arr) => {
  return arr.filter((it) => filteringAge(it) && filteringClinic(it));
};

const initAgeStateFromHash = () => {
  const matchHash = window.location.hash.substring(1).match(/age=([A-Za-z0-9_-]+)/gi);
  if (matchHash !== null) {
    const activeAge = matchHash[0].split("=")[1];
    const input = document.getElementById(activeAge);
    if(input) {
      input.checked = true;
      input.dispatchEvent(new Event('change', {bubbles : true}));
    }
  }
};

const changeDoctorsFormState = () => {
  if (!doctorsForm) return;

  setSpecialisationCounter(doctorsSpecialisationLink, data);
  renderDoctorsResult(data);
  doctorsForm.addEventListener('change', () => {
    removeSearchBlockWidth();
    specialisationResultBlock.classList.add(`doctor-nav__search-result--no-opacity`);
    doctorNav.classList.remove(`doctor-nav--active`);
    doctorsSpecialisationList.classList.add(`doctor-nav__specialisation-list--no-opacity`);
    setTimeout(() => {
      setSpecialisationCounter(doctorsSpecialisationLink, getFilteringData(data));
      renderDoctorsResult(getFilteringData(data));
      filterSpecialisation();
      doctorsSpecialisationList.classList.remove(`doctor-nav__specialisation-list--no-opacity`);
    }, 300);
  });

  const filterSpecializations = () => {
    const specializationList = document.querySelector('.specializations-modal .js-specialisation-list');
    const specialisationListLinks = specializationList.querySelectorAll('.specializations-modal__specialisation-link');

    const adultChecked = filtersModalForm.querySelector('.custom-input--adult input').checked;
    const childChecked = filtersModalForm.querySelector('.custom-input--child input').checked;

    const specializationsFilterAge = (el) => {
      if (adultChecked === childChecked) {
        return el;
      }

      if (adultChecked && !childChecked) {
        specialisationListLinks.forEach((e) => {
          const age = data2.find((specAge) => specAge.HASH_ID === e.dataset.specialisation).AGE;
          if (age.includes('adult') || age.length === 0) {
            e.parentNode.style.display = 'flex';
          } else {
            e.parentNode.style.display = 'none';
          }
        });
        return el.adult;
      }
      if (!adultChecked && childChecked) {
        specialisationListLinks.forEach((e) => {
          const age = data2.find((specAge) => specAge.HASH_ID === e.dataset.specialisation).AGE;
          if (age.includes('child') || age.length === 0) {
            e.parentNode.style.display = 'flex';
          } else {
            e.parentNode.style.display = 'none';
          }
        });
        return el.child;
      }
    };

    const specializationsFilteringClinic = (el) => {
      const clinicId = filtersModalForm.querySelector('.custom-input--select input').dataset.clinic || 'all';
      if (clinicId === 'all') {
        return el;
      }
      return el.clinic === clinicId;
    };

    const getSpecializationsFilteringData = (arr) => {
      return arr.filter((item) => specializationsFilterAge(item) && specializationsFilteringClinic(item));
    };

    setSpecialisationCounter(specialisationListLinks, getSpecializationsFilteringData(data));
  };

  initAgeStateFromHash();
};

export default changeDoctorsFormState;
