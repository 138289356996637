const selectorName = "js-link";

const initFakeLink = () => {

  document.addEventListener("click", (evt) => {
    if (evt.target.classList.contains(selectorName) || evt.target.parentElement.classList.contains(selectorName)) {
      evt.preventDefault();
      let target = evt.target.classList.contains(selectorName) ? evt.target : evt.target.parentElement;

      if (target.dataset.link.length) {
        if (target.dataset.target) {
          window.open(atob(target.dataset.link), target.dataset.target);
        } else {
          window.location = atob(target.dataset.link);
        }
      }
    }
  });

};

export default initFakeLink;
