import {disableScrolling, enableScrolling} from './utils';

const mobileMenuBtn = document.querySelector(`.js-menu-modal-open`);
const mobileModalBtn = document.querySelector(`.mobile-menu-btn`);

const mobileMenuAction = () => {
  if (mobileMenuBtn) {
    mobileMenuBtn.addEventListener(`click`, (evt) => {
      evt.preventDefault();
      disableScrolling();
      const sidebarIconBlockParent = document.querySelector('.mm-iconbar__bottom');
      const sidebarIconBlock = document.querySelector('.sidebar-icon');
      const sidebarIconIcon = document.querySelectorAll('.sidebar-icon span');
      const mainMoreLinks = document.querySelectorAll(`#mm-1 .mm-listview a`);
      const prevBtn = document.querySelector(`.mm-btn_prev`);
      const title = document.querySelector(`.mm-navbar__title`);
      const mmCloseBtn = document.querySelector(`.mm-btn_close`);
      let isSyncingLeftScroll = false;
      let isSyncingRightScroll = false;
      const leftDiv = document.querySelector(`.mm-iconbar__bottom`);
      const rightDiv = document.querySelector(`#mm-1`);

      mobileModalBtn.classList.add(`mobile-menu-btn--active`);

      sidebarIconIcon.forEach((el, i) => {
        el.addEventListener(`click`, () => {
          if (!prevBtn.getAttribute(`aria-owns`)) {
            mainMoreLinks[i].click();
          }
        });
      });

      leftDiv.onscroll = function () {
        if (!isSyncingLeftScroll) {
          isSyncingRightScroll = true;
          rightDiv.scrollTop = this.scrollTop;
        }
        isSyncingLeftScroll = false;
      };

      rightDiv.onscroll = function () {
        if (!isSyncingRightScroll) {
          isSyncingLeftScroll = true;
          leftDiv.scrollTop = this.scrollTop;
        }
        isSyncingRightScroll = false;
      };

      mmCloseBtn.addEventListener(`click`, () => {
        mobileModalBtn.classList.remove(`mobile-menu-btn--active`);
        mobileModalBtn.style = null;
        enableScrolling();
      });

      mainMoreLinks.forEach((link, index) => {
        link.addEventListener(`click`, () => {
          if (link.classList.contains(`mm-listitem__btn`)) {
            sidebarIconBlock.classList.add('sidebar-icon--active');
            sidebarIconBlockParent.classList.add('mm-iconbar__bottom--active');
            sidebarIconIcon[index].classList.add(`active-span`);
            mobileModalBtn.style = `transform: translateY(56px);`;
          }
        });
      });

      prevBtn.addEventListener(`click`, () => {
        if (prevBtn.getAttribute(`aria-owns`) === `mm-1`) {
          sidebarIconBlock.classList.remove('sidebar-icon--active');
          sidebarIconBlockParent.classList.remove('mm-iconbar__bottom--active');
          mobileModalBtn.style = null;
          sidebarIconIcon.forEach((span) => {
            span.classList.remove(`active-span`);
          });
        }
      });

      title.addEventListener(`click`, () => {
        if (prevBtn.getAttribute(`aria-owns`) === `mm-1`) {
          sidebarIconBlock.classList.remove('sidebar-icon--active');
          sidebarIconBlockParent.classList.remove('mm-iconbar__bottom--active');
          mobileModalBtn.style = null;
          sidebarIconIcon.forEach((span) => {
            span.classList.remove(`active-span`);
          });
        }
      });
    });
  }
};

export default mobileMenuAction;
