import {getParent, returnParent} from '../utils';
import setFormMode from '../form/select-patient-category';
import {toggleContent, setEventListener} from '../show-more';
import loadPage from '../paginate';
import {activateModal, initializeModal, openModal} from '../modals';

const ENTER_KEY_CODE = 13;
const allActivElement = document.querySelectorAll(`input, checkbox, a, button, textarea, radio, select, option`);
const customSelectsWrapper = document.querySelectorAll(`.custom-input__wrapper`);
const customSelectsInputs = document.querySelectorAll(`.custom-input__wrapper input`);
const customSelectsItems = document.querySelectorAll(`.custom-input__list li`);
const customSelectsLists = document.querySelectorAll(`.custom-input__list`);

const closeAllLists = () => {
  customSelectsWrapper.forEach((el) => {
    el.classList.remove(`custom-input__wrapper--show`);
  });
};

const documentClickHandler = (evt) => {
  if (getParent(evt.target, `custom-input__wrapper`)) {
    closeAllLists();
    document.removeEventListener(`click`, documentClickHandler);
  }
};

const initAddedOption = (li) => {
  const selectsHandler = () => {
    const parent = returnParent(li, 'custom-input__wrapper');
    const hiddenInput = parent.querySelector('input[type="hidden"]');
    parent.querySelector('input:not([type="hidden"])').value = li.innerHTML;
    if (li.dataset.value && hiddenInput) {
      hiddenInput.value = li.dataset.value;
    }

    closeAllLists();
    const inputWrapper = returnParent(li, 'custom-input__wrapper');
    const input = inputWrapper.querySelector('input');
    const changeEv = new Event('change');
    input.dispatchEvent(changeEv);
    const form = returnParent(li, 'form');
    if (form) {
      const formEv = new Event('change');
      form.dispatchEvent(formEv);
    }
  };

  li.addEventListener('click', selectsHandler);

  li.addEventListener('keydown', (evt) => {
    if (evt.keyCode === ENTER_KEY_CODE) {
      selectsHandler();
    }
  });
};

const customSelectAction = () => {
  if (customSelectsWrapper.length) {
    allActivElement.forEach((el) => {
      el.addEventListener(`focus`, () => {
        if (!el.parentNode.classList.contains(`custom-input__wrapper--show`)) {
          closeAllLists();
        }
      });
    });

    customSelectsItems.forEach((el) => {
      const selectsHandler = () => {
        const parent = returnParent(el, `custom-input__wrapper`);
        const hiddenInput = parent.querySelector('input[type="hidden"]');
        parent.querySelector('input:not([type="hidden"])').value = el.innerHTML;
        if (el.dataset.value && hiddenInput) {
          hiddenInput.value = el.dataset.value;
        }
        if (parent.querySelector('input').id === 'clinic-sidebar') {
          parent.querySelector('input').dataset.clinic = el.dataset.clinic;
        }

        if (el.classList.contains('country_selector')) {
          setFormMode();
        }
        closeAllLists();
        const inputWrapper = returnParent(el, `custom-input__wrapper`);
        const input = inputWrapper.querySelector(`input`);
        const changeEv = new Event(`change`);
        input.dispatchEvent(changeEv);
        const form = returnParent(el, `form`);
        if (form) {
          const formEv = new Event(`change`);
          form.dispatchEvent(formEv);
        }
      };

      el.addEventListener(`click`, selectsHandler);

      el.addEventListener(`keydown`, (evt) => {
        if (evt.keyCode === ENTER_KEY_CODE) {
          selectsHandler();
        }
      });
    });

    const showListOnClick = (evt) => {
      evt.preventDefault();
      document.addEventListener(`click`, documentClickHandler);
      if (evt.target.parentNode.classList.contains(`custom-input__wrapper--show`)) {
        closeAllLists();
      } else {
        evt.target.parentNode.classList.add(`custom-input__wrapper--show`);
      }
    };

    const showListOnKeydown = (evt) => {
      document.addEventListener(`click`, documentClickHandler);
      if (evt.keyCode === ENTER_KEY_CODE) {
        evt.preventDefault();
        if (evt.target.parentNode.classList.contains(`custom-input__wrapper--show`)) {
          closeAllLists();
        } else {
          evt.target.parentNode.classList.add(`custom-input__wrapper--show`);
        }
      }
    };

    const setBlogFilterReference = (input, form) => {

      let inputName = input.hasAttribute('name') ? input.getAttribute("name").slice(3) : "";

      if (window.blogReference && inputName && window.blogReference.hasOwnProperty(inputName)) {
        let selectValue = input.value,
          referenceValues = window.blogReference[inputName]["values"][selectValue],
          option = form.querySelector('[name=by-' + window.blogReference[inputName]["reference"] + ']').closest('div').querySelectorAll('li');

        option.forEach((li) => {
          li.style.display = 'list-item';
        });

        if (referenceValues) {
          option.forEach((li) => {
            let id = li.getAttribute('tabindex');
            li.style.display = (referenceValues.includes(id) || id == 0) ? 'list-item' : 'none';
          });
        }
      }
    };

    const filterSelect = (evt) => {
      if (evt.target.closest('.filter-select')) {

        evt.preventDefault();
        let form = evt.target.closest('.filter-form'),
          isBlogFilter = form.classList.contains('filter-form--blog');

        if (form) {

          let tags = form.querySelectorAll('.btn--tag-active');
          let tagsValue = [];
          let formData = new FormData(form);
          let query = '';
          let requestUrl = '';

          setBlogFilterReference(evt.target, form);

          if (tags.length) {
            tags.forEach((tag) => {
              tagsValue.push(tag.textContent.trim());
            });
          }

          if (tagsValue.length) {
            formData.set('tags', tagsValue);
          }

          let indexPair = 0;
          for (const pair of formData.entries()) {
            query += (indexPair++ ? '&' : '') + pair[0] + '=' + pair[1];
          }

          if(isBlogFilter) {
            requestUrl = location.pathname + `?${query}`;
          } else {
            requestUrl = location.href + `?is_ajax=y&${query}`;
          }

          fetch(requestUrl, {
            headers: {
              'bx-ajax': 'Y'
            }
          })
            .then((response) => response.text())
            .then((result) => {
              document.querySelector('.filter-previewes-inner').innerHTML = (result.trim() != '') ? result : 'По данной комбинации фильтров ничего не найдено';
              let showMoreReviewButtons = document.querySelectorAll(`.review .show-more`);
              let showMoreContainerClass = `.js-show-more-container`;
              if (showMoreReviewButtons.length) {
                showMoreReviewButtons.forEach((el) => {
                  setEventListener(el);
                });
              }
              let paginateBtn = document.querySelectorAll(`.btn-paginate`);
              if (paginateBtn.length) {
                loadPage(paginateBtn);
              }
              let reviewsModal = document.querySelector(`.js-add-reviews-modal`);
              let reviewsdBtns = document.querySelectorAll(`.js-reviews-modal-btn`);
              if (reviewsModal && reviewsdBtns.length) {
                activateModal(reviewsModal, reviewsdBtns, false, false);
              }
            });
        }
      }
    };

    document.querySelectorAll('.btn--tag-xl').forEach((tag) => {
      tag.addEventListener(`click`, (tag) => {
        if (!tag.target.classList.contains('btn--tag-active')) {
          tag.target.classList.add('btn--tag-active');
          filterSelect(tag);
        }
      });
    });

    document.querySelectorAll('.btn--remove-tag').forEach((tag) => {
      tag.addEventListener(`click`, (tag) => {
        if (tag.target.closest('.btn--tag-xl').classList.contains('btn--tag-active')) {
          tag.target.classList.add('filter-select');
          tag.target.closest('.btn--tag-xl').classList.remove('btn--tag-active');
          filterSelect(tag);
        }
      });
    });

    customSelectsInputs.forEach((input) => {
      input.addEventListener(`click`, showListOnClick);
      input.addEventListener(`keydown`, showListOnKeydown);
      input.addEventListener(`change`, filterSelect);
    });
    // Set Current if one value
    customSelectsLists.forEach((ul) => {
      if (ul.childNodes.length === 1) {
        const eventClick = new Event("click");
        ul.firstChild.dispatchEvent(eventClick);
      }
    });

    document.querySelectorAll('.filter-content-form').forEach((form) => {
      form.addEventListener('change', (event) => {

        event.preventDefault();
        let form = event.currentTarget,
          wrapperClass = form.dataset.wrapperClass,
          query = form.action + '?';

        const formData = new FormData(form);
        for (let pair of formData.entries()) {
          query += pair[0] + '=' + pair[1] + '&';
        }

        fetch(query.substr(0, query.length - 1), {
          headers: {
            'bx-ajax': 'Y'
          }
        }).then((response) => response.text())
          .then((result) => {
            document.querySelector(`.${wrapperClass}`).innerHTML = result;
          });
      });
    });
  }
};

const appoinmentFormSelect = (specialisationSelectForm, doctorSelectForm, doctorsSelectFormElements) => {
  if (specialisationSelectForm && doctorSelectForm && doctorsSelectFormElements.length) {
    const doctorsMenuForm = window.doctorsMenuForm || '[]';
    specialisationSelectForm.addEventListener(`change`, (evt) => {
      doctorSelectForm.value = '';
      let needsIdsDoctors = doctorsMenuForm[evt.srcElement.value];
      doctorsSelectFormElements.forEach((item) => {
        if (!needsIdsDoctors) {
          item.style.display = 'block';
        } else {
          if (needsIdsDoctors.includes(item.dataset.doctorid)) {
            item.style.display = 'block';
          } else {
            item.style.display = 'none';
          }
        }
      });
    });
  }
};

const specialisationSelectForm = document.querySelector(`#specialisation-select-form`);
const doctorSelectForm = document.querySelector(`#doctor-select-form`);
const doctorsSelectFormElements = document.querySelectorAll(`.doctors-select-input-wrapper .custom-input__list li`);

appoinmentFormSelect(specialisationSelectForm, doctorSelectForm, doctorsSelectFormElements);

const specialisationSelectFormMobile = document.querySelector(`#specialisation-select-form-mobile`);
const doctorSelectFormMobile = document.querySelector(`#doctor-select-form-mobile`);
const doctorsSelectFormElementsMobile = document.querySelectorAll(`.doctors-select-input-wrapper-mobile .custom-input__list li`);

appoinmentFormSelect(specialisationSelectFormMobile, doctorSelectFormMobile, doctorsSelectFormElementsMobile);

const serviceExitType = document.querySelector(`#form-service-exit_type`);
const serviceExitWrapper = document.querySelector(`#form-service-exit_wrap-count`);
const serviceExitInputCount = document.querySelector(`#form-service-exit_wrap-count input`);
const serviceExitTypeLegal = `Юридическое лицо`;
if (serviceExitType) {
  document.querySelector(`#form-service-exit_type`).addEventListener(`change`, (evt) => {
    serviceExitInputCount.value = "";
    serviceExitInputCount.removeAttribute(`required`);
    if (evt.target.value == serviceExitTypeLegal) {
      serviceExitWrapper.classList.remove('hidden');
      serviceExitInputCount.setAttribute(`required`, `required`);
    } else {
      serviceExitWrapper.classList.add('hidden');
    }
  });
}
export {customSelectAction, initAddedOption};
