import {disableScrolling, enableScrolling} from '../utils';

const storytellingOpenBtn = document.querySelectorAll(`.js-storytelling-open`);
const storytellingCloseBtn = document.querySelector(`.js-storytelling-close`);
const storytellingModal = document.querySelector(`.storytelling-modal`);
const storytellingArticles = document.querySelectorAll(`.storytelling-modal__article`);
const animateHeight = 300;

const animateArticles = () => {
  setTimeout(() => {
    storytellingArticles[0].classList.remove(`storytelling-modal__article--animate`);
  }, 1000);
  Array.prototype.forEach.call(storytellingArticles, (el) => {
    const article = el;
    storytellingModal.addEventListener(`scroll`, () => {
      const offset = innerHeight;
      const topSkillsContainer = article.getBoundingClientRect().top + animateHeight;
      if (offset >= topSkillsContainer) {
        if (article.classList.contains(`storytelling-modal__article--animate`)) {
          article.classList.remove(`storytelling-modal__article--animate`);
        }
      }
    });
  });
};

const removeArticleAimate = () => {
  Array.prototype.forEach.call(storytellingArticles, (el) => {
    const article = el;
    article.classList.add(`storytelling-modal__article--animate`);
  });
};

const openStorytellingModal = () => {
  storytellingModal.classList.add(`storytelling-modal--display`);
  disableScrolling();
  setTimeout(() => {
    storytellingModal.classList.remove(`storytelling-modal--animate`);
  });
  animateArticles();
};

const closeStorytellingModal = () => {
  storytellingModal.classList.add(`storytelling-modal--animate`);
  removeArticleAimate();
  setTimeout(() => {
    storytellingModal.classList.remove(`storytelling-modal--display`);
    enableScrolling();
  }, 600);
};

const onStorytellingOpenBtnClick = (evt) => {
  evt.preventDefault();
  openStorytellingModal();
  document.addEventListener(`keydown`, onEscKeyDown);
};

const onStorytellingCloseBtnClick = (evt) => {
  evt.preventDefault();
  closeStorytellingModal();
};

const onEscKeyDown = (evt) => {
  const isEscKey = evt.key === `Escape` || evt.key === `Esc`;
  if (isEscKey) {
    closeStorytellingModal();
    document.removeEventListener(`keydown`, onEscKeyDown);
  }
};

const storytellingModalAction = () => {
  if (storytellingOpenBtn.length && storytellingModal) {
    Array.prototype.forEach.call(storytellingArticles, (el) => {
      el.classList.add(`storytelling-modal__article--animate`);
    });
    storytellingModal.classList.add(`storytelling-modal--animate`);
    Array.prototype.forEach.call(storytellingOpenBtn, (el) => {
      const btn = el;
      btn.addEventListener(`click`, onStorytellingOpenBtnClick);
    });
    storytellingCloseBtn.addEventListener(`click`, onStorytellingCloseBtnClick);
  }
};

export default storytellingModalAction;
