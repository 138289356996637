const tabsBlocks = document.querySelectorAll(`.js-tabs-block`);
let shift = 200; // 300

const breakpoint = window.matchMedia(`(max-width:767px)`);
if (breakpoint.matches === true) {
  shift = 100;
}

const createTabsMarkup = (el) => {
  const content = el.innerText;
  return `
    <li>
      <a class="adaptive-tab__link js-adaptive-link" href="#">${content}</a>
    </li>
  `;
};

const createListTemplate = (arr) => {
  const newArr = Array.from(arr);
  const listItems = newArr.map((it) => createTabsMarkup(it)).join(` `);
  return `
    <ul class="adaptive-tab__list">${listItems}</ul>
  `;
};

const createMoreLinkTemplate = (arr) => {
  return `
    <li>
      <a class="tabs-block__tab adaptive-tab__link-more js-adaptive-link-more" href="#">${document.querySelectorAll(`.site-id-s2`).length ? "More" : "Ещё"}</a>
      ${createListTemplate(arr)}
    </li>
  `;
};

const renderMoreBlock = (block) => {
  const tabs = block.querySelectorAll(`.js-tab`);
  const blockList = block.querySelector(`.tabs-block__tabs-list`);

  blockList.insertAdjacentHTML(`beforeend`, createMoreLinkTemplate(tabs));

  const container = block.querySelector(`.adaptive-tab__list`);
  const newTabs = container.querySelectorAll(`.js-adaptive-link`);
  const moreLink = block.querySelector(`.js-adaptive-link-more`);

  moreLink.addEventListener(`click`, onMoreLinkClickHandler);

  setClickEvent(newTabs, block);
};

const setClickEvent = (arr, block) => {
  const tabs = block.querySelectorAll(`.js-tab`);
  arr.forEach((el, index) => {
    el.addEventListener(`click`, (e) => {
      const parent = el.closest(`.adaptive-tab__list`);
      e.preventDefault();
      parent.classList.remove(`adaptive-tab__list--active`);
      tabs[index].click();
    });
  });
};

const onMoreLinkClickHandler = (e) => {
  e.preventDefault();
  const parent = e.target.closest(`.tabs-block`);
  const moreTabList = parent.querySelector(`.adaptive-tab__list`);
  if (moreTabList.classList.contains(`adaptive-tab__list--active`)) {
    moreTabList.classList.remove(`adaptive-tab__list--active`);
  } else {
    moreTabList.classList.add(`adaptive-tab__list--active`);
  }
};

const returnTabIndex = (arr) => {
  let acc = 0;
  let i;
  const parent = arr[0].closest(`.tabs-block`).closest(`div`);
  arr.forEach((el, index) => {
    const margin = window
      .getComputedStyle(el.parentNode, null)
      .getPropertyValue(`margin-right`);
    const prettiMargin = Number(margin.slice(0, -2));
    acc += el.offsetWidth + prettiMargin;
    if (returnBlockWidth(parent) - shift < acc) {
      return;
    }
    i = index;
    //acc += el.offsetWidth + prettiMargin;
  });

  return i;
};

const returnBlockWidth = (block) => {
  if (block) {
    return block.offsetWidth;
  }
};

const checkMoreLinkBlock = (block, links) => {
  if (links[links.length - 1].parentNode.classList.contains('adaptive-tab--hide')) {
    block.classList.add('adaptive-tab--hide');
  } else {
    block.classList.remove('adaptive-tab--hide');
  }
};

const changeTabState = (index, list1, list2) => {
  list1.forEach((el, i) => {
    if (index >= i) {
      el.parentNode.classList.remove(`adaptive-tab--hide`);
    } else {
      el.parentNode.classList.add(`adaptive-tab--hide`);
    }
  });
  list2.forEach((el, i) => {
    if (index <= i - 1) {
      el.parentNode.classList.remove(`adaptive-tab--hide`);
    } else {
      el.parentNode.classList.add(`adaptive-tab--hide`);
    }
  });
};

const resizeTabBlocks = (block) => {
  const moreLink = block.querySelector(`.js-adaptive-link-more`);
  const moreLinks = block.querySelectorAll(`.js-adaptive-link`);
  const listLinks = block.querySelectorAll(`.js-tab`);

  changeTabState(returnTabIndex(listLinks), listLinks, moreLinks);
  checkMoreLinkBlock(moreLink, moreLinks);

  if (returnTabIndex(listLinks) <= listLinks.length) {
    moreLink.classList.add(`adaptive-tab__link-more--active`);
  } else {
    moreLink.classList.remove(`adaptive-tab__link-more--active`);
  }
};

const changeTabStateOnResize = (blocks) => {
  if (!blocks.length) {
    return;
  }
  blocks.forEach((block) => {
    renderMoreBlock(block);
    resizeTabBlocks(block);
  });
  window.addEventListener(`resize`, () => {
    blocks.forEach((block) => {
      resizeTabBlocks(block);
    });
  });
};

export default changeTabStateOnResize(tabsBlocks);
