import { createElement, renderElement } from '../utils';

const resultBlock = document.querySelector('.doctor-nav__result-block');
const resultBlockModal = document.querySelector('.doctors-modal .doctors-modal__content-block');

const createDoctorsElemMarcup = (data) =>
  data.map((el) => {
    return (
      `<li class="doctor-nav__result-item">
        <a class="doctor-nav__result-link" href="${el.href}" aria-label="Записаться на приём" data-specialisation="${el.specialisation}">
          <div class="doctor-nav__result-img">
            ${el.img ? `<img src="${el.img.src}" width="86" height="86" alt="${el.img.alt}"></img>` : ``}
          </div>
          <div class="doctor-nav__result-info">
            <div class="doctor-nav__result-title">${el.name}</div>
            <p>${el.description}</p>
          </div>
        </a>
      </li>`
    );
  }).join('');

const createSearchElemTemplate = (data) => {
  const content = createDoctorsElemMarcup(data);
  return (
    `<ul class="doctor-nav__result-list">${content}</ul>`
  );
};

const renderDoctorsResult = (data, isMobile) => {
  const container = isMobile ? resultBlockModal : resultBlock;
  container.innerHTML = '';
  renderElement(container, createElement(createSearchElemTemplate(data)));
};

export default renderDoctorsResult;
