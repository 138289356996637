import {disableScrolling, enableScrolling} from '../utils';

const menuOpenBtns = document.querySelectorAll(`.js-menu-open`);
const menuCloseBtn = document.querySelector(`.js-menu-close`);
const menuModal = document.querySelector(`.menu-modal`);
const menuModalLinks = document.querySelectorAll(`.menu-modal a`);

const openMenuModal = () => {
  menuModal.classList.add(`menu-modal--display`);
  disableScrolling();
  setTimeout(() => {
    menuModal.classList.remove(`menu-modal--animate`);
  });
};

const closeMenuModal = ({target}) => {
  menuModal.classList.add(`menu-modal--animate`);
  setTimeout(() => {
    if (!target.closest(`.js-call-doctor-btn`) && !target.closest(`.js-ambulance-btn`)) {
      enableScrolling();
    }
    menuModal.classList.remove(`menu-modal--display`);
  }, 600);
};

const closeMenuModalOnResize = () => {
  menuModal.classList.add(`menu-modal--animate`);
  menuModal.classList.remove(`menu-modal--display`);
  enableScrolling();
  document.activeElement.blur();
};

const breakpoint = window.matchMedia(`(max-width:767px)`);
const breakpointChecker = () => {
  const mobileModalBtn = document.querySelector(`.mobile-menu-btn`);

  if (mobileModalBtn) {
    if (breakpoint.matches) {
      closeMenuModalOnResize();
    } else {
      const sidebarIconBlock = document.querySelector(`.sidebar-icon`);
      const sidebarIconIcon = document.querySelectorAll(`.sidebar-icon span`);
      sidebarIconIcon.forEach((span) => {
        span.classList.remove(`active-span`);
      });
      mobileModalBtn.classList.remove(`mobile-menu-btn--active`);
      mobileModalBtn.style = null;
      sidebarIconBlock.classList.remove(`sidebar-icon--active`);
      window.menu.API.closeAllPanels().close();
      enableScrolling();
    }
  }
};

window.closeMMenu = () => {
  const mobileModalBtn = document.querySelector(`.mobile-menu-btn`);
  const sidebarIconBlock = document.querySelector(`.sidebar-icon`);
  const sidebarIconIcon = document.querySelectorAll(`.sidebar-icon span`);
  sidebarIconIcon.forEach((span) => {
    span.classList.remove(`active-span`);
  });
  mobileModalBtn.classList.remove(`mobile-menu-btn--active`);
  mobileModalBtn.style = null;
  sidebarIconBlock.classList.remove(`sidebar-icon--active`);
  window.menu.API.closeAllPanels().close();
};

breakpoint.addListener(breakpointChecker);

const onMenuOpenBtnClick = (evt) => {
  evt.preventDefault();
  openMenuModal();
  document.addEventListener(`keydown`, onEscKeyDown);
};

const onMenuCloseBtnClick = (evt) => {
  evt.preventDefault();
  closeMenuModal(evt);
};

const onEscKeyDown = (evt) => {
  const isEscKey = evt.key === `Escape` || evt.key === `Esc`;
  if (isEscKey) {
    closeMenuModal(evt);
    document.removeEventListener(`keydown`, onEscKeyDown);
  }
};

const menuModalAction = () => {
  if (menuOpenBtns.length && menuModal) {
    menuModal.classList.add(`menu-modal--animate`);
    menuOpenBtns.forEach((el) => {
      const btn = el;
      btn.addEventListener(`click`, onMenuOpenBtnClick);
    });
    menuCloseBtn.addEventListener(`click`, onMenuCloseBtnClick);
  }

  if (menuModalLinks.length) {
    menuModalLinks.forEach((el) => {
      const link = el;
      link.addEventListener(`click`, closeMenuModal);
    });
  }
};

export default menuModalAction;
