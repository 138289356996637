import {returnParent} from '../utils';

const renderResultBtns = document.querySelectorAll(`.js-result-modal-btn`);
const renderResultModal = document.querySelector(`.result-modal`);

const renderResultModalContent = () => {
  if (renderResultBtns.length && renderResultModal) {
    renderResultBtns.forEach((el) => {
      el.addEventListener(`click`, () =>{
        const parent = returnParent(el, `calculation-steps__item`);
        const content = parent.querySelector(`.calculation-steps__result-aside`).innerHTML;
        renderResultModal.innerHTML = ``;
        renderResultModal.innerHTML = content;
      });
    });
  }
};

export default renderResultModalContent;
