const inputBlocks = document.querySelectorAll('.input-file');

const initInputFile = () => {

  if(!inputBlocks)
    return;

  const getElementIndex = (element) => {
    return Array.prototype.indexOf.call(element.parentNode.children, element);
  };

  const removeFile = (input, indexFile) => {
    let dt = new DataTransfer();

    Array.from(input.files).forEach((file, i) => {
      if (i !== indexFile) {
        dt.items.add(input.files[i]);
      }
    });
    input.files = dt.files;

    return dt;
  };

  inputBlocks.forEach(block => {
    const input = block.querySelector('input'),
      listBlock = block.querySelector('.input-file__list'),
      form = block.closest('form');

    let filesList = new DataTransfer();

    form.addEventListener('reset', () => {
      filesList = new DataTransfer();
      listBlock.innerHTML = '';
    });

    input.addEventListener('change', () => {
      let files = input.files;
      listBlock.innerHTML = '';
      Array.from(files).forEach(file => {
        filesList.items.add(file);
      });
      Array.from(filesList.files).forEach(file => {
        let htmlFile = `<div class="input-file__item"><span class="input-file__name">${file.name}</span><button class="input-file__delete" type="button"><svg width="14" height="15"><use xlink:href="/local/templates/gms/img/sprite_auto.svg#basket"></use></svg><span>Удалить</span></button></div>`;
        listBlock.insertAdjacentHTML('beforeend', htmlFile);
      });
      input.files = filesList.files;
    });

    block.addEventListener('click', event => {
      if (event.target.closest('.input-file__delete')) {
        filesList = removeFile(input, getElementIndex(event.target.closest('.input-file__item')));
        event.target.closest('.input-file__item').remove();
      }
    });

  });
};


export default initInputFile;
