import {renderSearchResult} from '../page-main/search-form';

const searchDoctorsBlock = document.querySelector(`.doctor-search`);
const dataSearchJson = window.searchDoctors || '[]';
const dataSearch = JSON.parse(dataSearchJson);

const initDoctorsSearch = () => {
  if (searchDoctorsBlock) {
    renderSearchResult(searchDoctorsBlock, dataSearch, true);
  }
};

export default initDoctorsSearch;
