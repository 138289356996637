import {toggleContent, setEventListener} from './show-more';

let paginateBtn = document.querySelectorAll('.btn-paginate');
let author = document.querySelector('#by-authors');
let speciality = document.querySelector('#by-speciality');
let tagsBlock = document.querySelector('#by-tags');

const loadPage = (paginateBtnCollection = false) => {
  if (paginateBtnCollection) {
      paginateBtn = paginateBtnCollection;
  }
  paginateBtn.forEach((el) => {
    el.addEventListener(`click`, () => {
      let navNum = el.dataset.navnum;
      let count = parseInt(el.dataset.count);
      let pagen = parseInt(el.dataset.pagen);
      let allPagen = parseInt(el.dataset.allpagen);
      let all = parseInt(el.dataset.all);
      let container = document.querySelector(`.ajax_pagen_wrap_${navNum}`);
      let paramAuthor = '';
      let paramSpeciality = '';
      let paramTags = '';

      if(author)
        paramAuthor = `&by-author=${author.value}`;

      if(speciality)
        paramSpeciality = `&by-speciality=${speciality.value}`;

      if(tagsBlock) {
        let tags = tagsBlock.querySelectorAll('button.btn--tag-active');
        let tagsValue = [];

        if (tags.length) {
          tags.forEach((tag) => {
            tagsValue.push(tag.textContent.trim());
          });

          paramTags = `&tags=${tagsValue.join()}`;
        }
      }

      fetch(location.pathname + `?PAGEN_${navNum}=${(pagen + 1)}&is_ajax=y${paramAuthor}${paramSpeciality}${paramTags}`,{
        headers: {
          'bx-ajax': 'Y'
        }
      })
        .then((response) => response.text())
        .then((result) => {
          el.insertAdjacentHTML('beforebegin', result);
          el.dataset.pagen = pagen + 1;
          if(allPagen <= (pagen + 1)) {
            el.style.display = 'none';
          }

          if((allPagen - 2) == pagen && !isNaN(all)) {
            let label = `Загрузить еще ${(all % count)} из ${all}`;
            if (document.querySelectorAll(`.site-id-s2`).length)
              label = `Load more ${(all % count)} from ${all}`;

            el.setAttribute('aria-label', label);
            el.innerHTML = `<svg width="17" height="17">
                <use xlink:href="/local/templates/gms/img/sprite_auto.svg#plus"></use>
            </svg>${label}`;
          }

          let showMoreReviewButtons = document.querySelectorAll(`.review .show-more`);
          let showMoreContainerClass = `.js-show-more-container`;
          if (showMoreReviewButtons.length) {
            showMoreReviewButtons.forEach((el) => {
              setEventListener(el);
            });
          }
        });
    });
  });
};

export default loadPage;
