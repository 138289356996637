const phoneInputs = document.querySelectorAll(`.js-tel input`);

const validatePhone = () => {
  if (phoneInputs.length) {
    phoneInputs.forEach((input) => {
      // eslint-disable-next-line no-undef
      return new IMask(input, {
        mask: `+{7}(000)000-00-00[0]`
      });
    });
  }
};

export default validatePhone;
