const doctorsSpecialisationList = document.querySelector('.js-specialisation-list');
const doctorsSpecialisationItems = document.querySelectorAll('.doctor-nav__search-block .js-specialisation-list li');
const radioLetters = document.querySelectorAll('.js-letters-block input');

const returnCheckedRadioValue = () => {
  let currentLetter = '';
  if (radioLetters[0].checked) {
    return 'all';
  }

  radioLetters.forEach((el) => {
    if (el.checked) {
      currentLetter = el.value.toLowerCase();
    }
  });
  return currentLetter;
};

const filterSpecialisation = () => {
  doctorsSpecialisationList.innerHTML = '';
  if (returnCheckedRadioValue() === 'all') {
    doctorsSpecialisationItems.forEach((el) => {
      doctorsSpecialisationList.appendChild(el);
    });
  }
  const arr = [];
  doctorsSpecialisationItems.forEach((el) => {
    if (el.querySelector('.doctor-nav__specialisation-title').textContent.charAt(0).toLowerCase() === returnCheckedRadioValue()) {
      arr.push(el);
    }
  });

  arr.forEach((el) => {
    doctorsSpecialisationList.appendChild(el);
  });
};

export default filterSpecialisation;
