const freeModeSettings = {
  slidesPerView: `auto`,
  navigation: {
    nextEl: `.swiper-free-button-next`,
    prevEl: `.swiper-free-button-prev`,
  },
};

const threeModeSettings = {
  slidesPerView: 3,
};

const twoModeSettings = {
  slidesPerView: 2,
};

const doctorModeSettings = {
  slidesPerView: `auto`,
  navigation: {
    nextEl: `.swiper-doctors-button-next`,
    prevEl: `.swiper-doctors-button-prev`,
  },
};

const moscowClinicsModeSettings = {
  updateOnWindowResize: true,
  slidesPerView: 4,
  sliderPerGroup: 1,
  spaceBetween: 30,
  initialSlide: 0,
  speed: 1000,
  navigation: {
    nextEl: `.swiper-moscowclinic-button-next`,
    prevEl: `.swiper-moscowclinic-button-prev`,
  },
  breakpoints: {
    1366: {
      slidesPerView: 4,
      sliderPerGroup: 1,
    },
    1024: {
      slidesPerView: 3,
      sliderPerGroup: 1,
      spaceBetween: 18,
    },
    768: {
      slidesPerView: 2,
      sliderPerGroup: 1,
      spaceBetween: 30,
    },
    320: {
      slidesPerView: 1.10,
      sliderPerGroup: 1,
      spaceBetween: 8,
    }
  }
};

const spbClinicsModeSettings = {
  updateOnWindowResize: true,
  slidesPerView: 4,
  sliderPerGroup: 1,
  spaceBetween: 30,
  initialSlide: 0,
  speed: 1000,
  navigation: {
    nextEl: `.swiper-spbclinics-button-next`,
    prevEl: `.swiper-spbclinics-button-prev`,
  },
  breakpoints: {
    1366: {
      slidesPerView: 4,
      sliderPerGroup: 1,
    },
    1024: {
      slidesPerView: 3,
      sliderPerGroup: 1,
      spaceBetween: 18,
    },
    768: {
      slidesPerView: 2,
      sliderPerGroup: 1,
      spaceBetween: 30,
    },
    320: {
      slidesPerView: 1.10,
      sliderPerGroup: 1,
      spaceBetween: 8,
    }
  }
};

const womenServiceModeSettings = {
  slidesPerView: `auto`,
  navigation: {
    nextEl: `.swiper-womenservice-button-next`,
    prevEl: `.swiper-womenservice-button-prev`,
  },
};

const menServiceModeSettings = {
  slidesPerView: `auto`,
  navigation: {
    nextEl: `.swiper-menservice-button-next`,
    prevEl: `.swiper-menservice-button-prev`,
  },
};

const childrenServiceModeSettings = {
  slidesPerView: `auto`,
  navigation: {
    nextEl: `.swiper-childrenservice-button-next`,
    prevEl: `.swiper-childrenservice-button-prev`,
  },
};

const storiesModeSettings = {
  loop: true,
  speed: 600,
  slidesPerView: `auto`,
  pagination: {
    el: `.main-stories__slider-pagination`,
    clickable: true
  },
};

const checkupModeSettings = {
  speed: 600,
  loop: true,
  slidesPerView: `auto`,
  navigation: {
    nextEl: `.swiper-button-next`,
    prevEl: `.swiper-button-prev`,
  },
};

const certificateSettings = {
  speed: 600,
  slidesPerView: `auto`,
  navigation: {
    nextEl: `.certificate-slider__button-next`,
    prevEl: `.certificate-slider__button-prev`,
  },
};

const calculationSliderSettings = {
  speed: 200,
  effect: 'fade',
  pagination: {
    el: `.swiper-pagination`,
    clickable: true,
  },
  navigation: {
    nextEl: `.calculation-steps__button-next`,
    prevEl: `.calculation-steps__button-prev`,
  },
};

const initSlider = (el, settings) => {
  // eslint-disable-next-line no-undef
  return new Swiper(el, settings);
};


export {freeModeSettings, storiesModeSettings, calculationSliderSettings, certificateSettings, checkupModeSettings, doctorModeSettings, moscowClinicsModeSettings, spbClinicsModeSettings, threeModeSettings, twoModeSettings, initSlider, womenServiceModeSettings, menServiceModeSettings, childrenServiceModeSettings};
