const tabsSlider = document.querySelector(`.direction-search__tabs`);
const breakpoint = window.matchMedia(`(max-width:768px)`);
let mySwiper;
const enableSwiper = () => {
  // eslint-disable-next-line no-undef
  mySwiper = new Swiper(tabsSlider, {
    updateOnWindowResize: true,
    sliderPerGroup: 1,
    spaceBetween: 5,
    slidesPerView: `auto`,
    initialSlide: 0,
    speed: 1000,
  });
};
const breakpointChecker = function () {
  if (breakpoint.matches === true) {
    enableSwiper();
  } else {
    if (mySwiper) {
      mySwiper.destroy();
    }
  }
};
const tabsSliderInit = function () {
  if (tabsSlider) {
    breakpointChecker();
    window.onresize = breakpointChecker;
  }

  if (!tabsSlider) {
    return;
  }
  breakpointChecker();
  window.onresize = breakpointChecker;
};

export default tabsSliderInit;
