import {renderSearchResult} from '../page-main/search-form';

const searchDirectionBlock = document.querySelector(`.direction-search`);
const dataSearchJson = window.searchDirections || '[]';
const dataSearch = JSON.parse(dataSearchJson);

const initDirectionsSearch = () => {
  if (searchDirectionBlock) {
    renderSearchResult(searchDirectionBlock, dataSearch, true);
  }
};

export default initDirectionsSearch;
