const logoSliders = document.querySelectorAll(`.logo-slider`);
const initLogoSlider = () => {
  if (logoSliders.length) {
    logoSliders.forEach((el) => {
      // eslint-disable-next-line no-undef
      const newSlider = new Swiper(el, {
        updateOnWindowResize: true,
        slidesPerView: `auto`,
        sliderPerGroup: 1,
        spaceBetween: 12,
        initialSlide: 0,
        speed: 1000,
        loop: true,
        breakpoints: {
          768: {
            spaceBetween: 12,
          },
          320: {
            spaceBetween: 4,
          }
        }
      });
    });
  }
};

export default initLogoSlider;
