const numInputsRegEx = /[^\d]/g;
const numInputs = document.querySelectorAll(`.js-num input`);

const validateNumInputs = () => {
  if (numInputs.length) {
    numInputs.forEach((el) => {
      const input = el;
      input.addEventListener(`input`, () => {
        input.value = input.value.replace(numInputsRegEx, ``);
      });
    });
  }
};


const numInputAction = () => {
  validateNumInputs();
};

export default numInputAction;
