import {initSlider, calculationSliderSettings} from '../swiper-settings';
import {createElement, renderElement} from "../utils";

const inputs = document.querySelectorAll(`.calculation-steps input`);
const stepBlocks = document.querySelectorAll(`.calculation-steps__item`);
const firstStepBlock = document.querySelector(`.calculation-steps__item--first-step`);
const firstStepBlockForm = document.querySelector(`.calculation-steps__item--first-step form`);
const secondStepBlock = document.querySelector(`.calculation-steps__item--second-step`);
const secondStepBlockForm = document.querySelector(`.calculation-steps__item--second-step form`);
const secondStepProgramBlock = document.querySelector(`.calculation-steps__item--second-step .calculation-steps__program-block`);
const secondStepMobileBtn = document.querySelector(`.calculation-steps__item--second-step .calculation-steps__result-mobile-btn`);
const thirdStepBlock = document.querySelector(`.calculation-steps__item--third-step`);
const thirdStepBlockForm = document.querySelector(`.calculation-steps__item--third-step form`);
const thirdStepProgramBlock = document.querySelector(`.calculation-steps__item--third-step .calculation-steps__program-block`);
const thirdStepMobileBtn = document.querySelector(`.calculation-steps__item--third-step .calculation-steps__result-mobile-btn`);

/*const fourthStepBlock = document.querySelector(`.calculation-steps__item--fourth-step`);
const fourthStepBlockForm = document.querySelector(`.calculation-steps__item--fourth-step form`);
const fourthStepProgramBlock = document.querySelector(`.calculation-steps__item--fourth-step .calculation-steps__program-block`);
const fourthStepMobileBtn = document.querySelector(`.calculation-steps__item--fourth-step .calculation-steps__result-mobile-btn`);*/

const fifthStepBlock = document.querySelector(`.calculation-steps__item--fifth-step`);
const fifthStepProgramBlock = document.querySelector(`.calculation-steps__item--fifth-step .calculation-steps__program-block`);

const progressBar = document.querySelector(`.program-calculation__progress-bar-result`);
const calculationStepsSliderBlock = document.querySelector(`.calculation-steps__slider-curator`);
const footer = document.querySelector(`footer`);
const calcPrice = window.calcPrice || '[]';
const data = JSON.parse(calcPrice);
const WIDTH_STEP = 20;

const prettifyRubles = (num) => {
  let n = num.toString();
  return (
    n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, `$1` + ` `) + ` ₽`
  );
};

const innerPrice = (el, price) => {
  const priceBlock = el.querySelector(`.calculation-steps__result-price`);
  if(price == 'Индивидуально')
    priceBlock.innerHTML = 'Индивидуально';
  else
    priceBlock.innerHTML = prettifyRubles(price);
};

const changeBarState = (count) => {
  progressBar.style.width = count * WIDTH_STEP + `%`;
};

const summPrice = (arr) => {
  let price = 0;
  arr.forEach((el) => {
    price = price + data[el];
  });
  return price;
};

const showStep = (block1, block2) => {
  setTimeout(() => {
    block1.classList.add(`calculation-steps__item--no-opacity`);
  }, 300);
  setTimeout(() => {
    block1.classList.add(`calculation-steps__item--hidden`);
    block2.classList.remove(`calculation-steps__item--hidden`);
  }, 600);
  setTimeout(() => {
    block2.classList.remove(`calculation-steps__item--no-opacity`);
  }, 700);
};

const findAllActiveInputs = () => {
  const arr = [];
  inputs.forEach((el) => {
    if (el.checked) {
      arr.push(el.id);
    }
  });
  return arr;
};

const findActiveInputs = (form) => {
  const formInputs = form.querySelectorAll(`input`);
  const arr = [];
  formInputs.forEach((el) => {
    if (el.checked) {
      arr.push(el.id);
    }
  });
  return arr;
};

const findActiveValue = (form) => {
  const formInputs = form.querySelectorAll(`input`);
  const arr = [];
  formInputs.forEach((el) => {
    if (el.checked) {
      arr.push(el.value);
    }
  });
  return arr;
};

const createProgramArray = () => {
  let arr = [];
  arr = arr.concat(findActiveValue(firstStepBlockForm))
      .concat(findActiveValue(secondStepBlockForm))
      .concat(findActiveValue(thirdStepBlockForm))
      //.concat(findActiveValue(fourthStepBlockForm));
  return arr;
};

const createProgramListMarcup = (arr) =>
  arr.map((el) => {
    return `<li><a class="btn btn--tag-xl btn--gray" href="#" aria-label="${el}">${el}</a></li>`;
  }).join(``);

const createProgramTitleTemplate = () => {
  return `<span>Ваша индивидуальная программа включает</span>`;
};

const createProgramListTemplate = (arr) => {
  const content = createProgramListMarcup(arr);
  return `<ul class="calculation-steps__program-list">${content}</ul>`;
};

const changeService2Step = () => {
  let inputAge = document.querySelector('[name=age]:checked');
  let inputServices = document.querySelectorAll('.calculation-steps__item--second-step ul.custom-input--checkbox li');
  if(inputAge) {
    let age = inputAge.getAttribute('id');
    inputServices.forEach((el) => {
      el.style.display = 'none';
      el.querySelector('input').removeAttribute('checked');
      el.querySelector('input').removeAttribute('disabled');
    });

    data.SERVICES[age].map((arr) => {
      document.querySelector(`#${arr.serviceCode}`).parentNode.style.display = 'flex';

      if(arr.isRequired)
      {
        document.querySelector(`#${arr.serviceCode}`).setAttribute('checked', 'checked');
        document.querySelector(`#${arr.serviceCode}`).setAttribute('disabled', 'disabled');
      }
    });
  }
};

const priceFirstStep = () => {
  let inputAge = document.querySelector('[name=age]:checked');
  let price = 'Индивидуально';
  if(inputAge) {
    let age = inputAge.getAttribute('id');

    if(data.PRICES.STEP_1[age] > 0)
      price = data.PRICES.STEP_1[age];
  }

  return price;
};

const priceSecondStep = () => {
  let inputAge = document.querySelector('[name=age]:checked');
  let price = 'Индивидуально';
  if(inputAge) {
    let age = inputAge.getAttribute('id');

    if(age == 'do-1-goda' && document.querySelector('#gospitalizatsiya-na-3-dnya').checked)
      price = data.PRICES.STEP_2[age]['gospitalizatsiya-na-3-dnya'];
    else if(age == 'do-1-goda' && document.querySelector('#gospitalizatsiya-na-5-dney').checked)
      price = data.PRICES.STEP_2[age]['gospitalizatsiya-na-5-dney'];
    else if(age == 'do-1-goda')
      price = data.PRICES.STEP_1[age];

    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#pomoshch-na-domu').checked && document.querySelector('#stomatologiya').checked)
      price = data.PRICES.STEP_2[age]['pomoshch-na-domu_stomatologiya'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#pomoshch-na-domu').checked)
      price = data.PRICES.STEP_2[age]['pomoshch-na-domu'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#stomatologiya').checked)
      price = data.PRICES.STEP_2[age]['stomatologiya'];
    else if(age == 'ot-1-goda-do-60-let')
      price = data.PRICES.STEP_1[age];
  }

  let inputArr = document.querySelectorAll(".m-checkbox");
  let checkedMCheckbox = false;
  if (inputArr.length) {
      inputArr.forEach(function(el) {
          el.addEventListener("click", function(e) {

              if (el.checked) {
                  checkedMCheckbox = true;
              } 
              inputArr.forEach(function(el1) {
                  el1.checked = false;
              });
              if (!checkedMCheckbox) {
                  el.checked = false;
              } else {
                  el.checked = true;
              }
              checkedMCheckbox = false;
          });
      });
  }

  return price;
};

const priceThirdStep = () => {
  let inputAge = document.querySelector('[name=age]:checked');
  let price = 'Индивидуально';
  if(inputAge) {
    let age = inputAge.getAttribute('id');

    if(age == 'do-1-goda' && document.querySelector('#gospitalizatsiya-na-3-dnya').checked && document.querySelector('#khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista').checke)
      price = data.PRICES.STEP_3[age]['gospitalizatsiya-na-3-dnya']['khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista'];
    else if(age == 'do-1-goda' && document.querySelector('#gospitalizatsiya-na-3-dnya').checked && document.querySelector('#khochu-byt-prikreplyen-k-vedushchemu-spetsialistu').checke)
      price = data.PRICES.STEP_3[age]['gospitalizatsiya-na-3-dnya']['khochu-byt-prikreplyen-k-vedushchemu-spetsialistu'];
    else if(age == 'do-1-goda' && document.querySelector('#gospitalizatsiya-na-5-dney').checked && document.querySelector('#khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista').checked)
      price = data.PRICES.STEP_3[age]['gospitalizatsiya-na-5-dney']['khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista'];
    else if(age == 'do-1-goda' && document.querySelector('#gospitalizatsiya-na-5-dney').checked && document.querySelector('#khochu-byt-prikreplyen-k-vedushchemu-spetsialistu').checked)
      price = data.PRICES.STEP_3[age]['gospitalizatsiya-na-5-dney']['khochu-byt-prikreplyen-k-vedushchemu-spetsialistu'];
    else if(age == 'do-1-goda' && document.querySelector('#khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista').checked)
      price = data.PRICES.STEP_3[age]['base']['khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista'];
    else if(age == 'do-1-goda' && document.querySelector('#khochu-byt-prikreplyen-k-vedushchemu-spetsialistu').checked)
      price = data.PRICES.STEP_3[age]['base']['khochu-byt-prikreplyen-k-vedushchemu-spetsialistu'];
    else if(age == 'do-1-goda' && document.querySelector('#gospitalizatsiya-na-3-dnya').checked)
      price = data.PRICES.STEP_2[age]['gospitalizatsiya-na-3-dnya'];
    else if(age == 'do-1-goda' && document.querySelector('#gospitalizatsiya-na-5-dney').checked)
      price = data.PRICES.STEP_2[age]['gospitalizatsiya-na-5-dney'];
    else if(age == 'do-1-goda')
      price = data.PRICES.STEP_1[age];

    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#pomoshch-na-domu').checked && document.querySelector('#stomatologiya').checked && document.querySelector('#khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista').checked)
      price = data.PRICES.STEP_3[age]['pomoshch-na-domu_stomatologiya']['khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#pomoshch-na-domu').checked && document.querySelector('#stomatologiya').checked && document.querySelector('#khochu-byt-prikreplyen-k-vedushchemu-spetsialistu').checked)
      price = data.PRICES.STEP_3[age]['pomoshch-na-domu_stomatologiya']['khochu-byt-prikreplyen-k-vedushchemu-spetsialistu'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#pomoshch-na-domu').checked && document.querySelector('#khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista').checked)
      price = data.PRICES.STEP_3[age]['pomoshch-na-domu']['khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#pomoshch-na-domu').checked && document.querySelector('#khochu-byt-prikreplyen-k-vedushchemu-spetsialistu').checked)
      price = data.PRICES.STEP_3[age]['pomoshch-na-domu']['khochu-byt-prikreplyen-k-vedushchemu-spetsialistu'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#stomatologiya').checked && document.querySelector('#khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista').checked)
      price = data.PRICES.STEP_3[age]['stomatologiya']['khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#stomatologiya').checked && document.querySelector('#khochu-byt-prikreplyen-k-vedushchemu-spetsialistu').checked)
      price = data.PRICES.STEP_3[age]['stomatologiya']['khochu-byt-prikreplyen-k-vedushchemu-spetsialistu'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista').checked)
      price = data.PRICES.STEP_3[age]['base']['khochu-imet-vozmozhnost-poseshchat-vedushchego-spetsialista'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#khochu-byt-prikreplyen-k-vedushchemu-spetsialistu').checked)
      price = data.PRICES.STEP_3[age]['base']['khochu-byt-prikreplyen-k-vedushchemu-spetsialistu'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#pomoshch-na-domu').checked && document.querySelector('#stomatologiya').checked)
      price = data.PRICES.STEP_2[age]['pomoshch-na-domu_stomatologiya'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#pomoshch-na-domu').checked)
      price = data.PRICES.STEP_2[age]['pomoshch-na-domu'];
    else if(age == 'ot-1-goda-do-60-let' && document.querySelector('#stomatologiya').checked)
      price = data.PRICES.STEP_2[age]['stomatologiya'];
    else if(age == 'ot-1-goda-do-60-let')
      price = data.PRICES.STEP_1[age];
  }

  return price;
};

const firstStep = () => {
  firstStepBlockForm.addEventListener(`change`, () => {
    const price = priceFirstStep(); //summPrice(findAllActiveInputs());
    innerPrice(secondStepBlock, price);
    innerPrice(secondStepMobileBtn, price);
    changeBarState(2);
    showStep(firstStepBlock, secondStepBlock);
    secondStep();
    setTimeout(() => {
      footer.classList.add(`footer--mobile-hidden`);
    }, 600);
    secondStepProgramBlock.innerHTML = ``;

    changeService2Step();
    const dataProgram = createProgramListTemplate(createProgramArray());
    renderElement(secondStepProgramBlock, createElement(createProgramTitleTemplate()));
    renderElement(secondStepProgramBlock, createElement(dataProgram));
  });
};

const secondStep = () => {
  const btnNext = secondStepBlock.querySelector(`.js-next-step`);
  const btnPrev = secondStepBlock.querySelector(`.js-prev-step`);
  let price = priceSecondStep(); //summPrice(findAllActiveInputs());
  secondStepBlockForm.addEventListener(`change`, () => {
    price = priceSecondStep(); //summPrice(findAllActiveInputs());
    secondStepProgramBlock.innerHTML = ``;
    const dataProgram = createProgramListTemplate(createProgramArray());
    renderElement(secondStepProgramBlock, createElement(createProgramTitleTemplate()));
    renderElement(secondStepProgramBlock, createElement(dataProgram));
    if (findActiveInputs(secondStepBlockForm).length > 0) {
      btnNext.disabled = false;
      innerPrice(secondStepBlock, price);
      innerPrice(secondStepMobileBtn, price);
    } else {
      btnNext.disabled = true;
      innerPrice(secondStepBlock, price);
      innerPrice(secondStepMobileBtn, price);
    }
  });
  btnPrev.addEventListener(`click`, () => {
    showStep(secondStepBlock, firstStepBlock);
    changeBarState(1);
    firstStepBlockForm.reset();
    setTimeout(() => {
      footer.classList.remove(`footer--mobile-hidden`);
      secondStepBlockForm.reset();
    }, 600);
  });
  btnNext.addEventListener(`click`, () => {
    innerPrice(thirdStepBlock, price);
    innerPrice(thirdStepMobileBtn, price);
    showStep(secondStepBlock, thirdStepBlock);
    changeBarState(3);
    thirdStep();
    thirdStepProgramBlock.innerHTML = ``;
    const dataProgram = createProgramListTemplate(createProgramArray());
    renderElement(thirdStepProgramBlock, createElement(createProgramTitleTemplate()));
    renderElement(thirdStepProgramBlock, createElement(dataProgram));
  });
};

const thirdStep = () => {
  const btnNext = thirdStepBlock.querySelector(`.js-next-step`);
  const btnPrev = thirdStepBlock.querySelector(`.js-prev-step`);
  let price = priceThirdStep(); //summPrice(findAllActiveInputs());
  thirdStepBlockForm.addEventListener(`change`, () => {
    price = priceThirdStep(); //summPrice(findAllActiveInputs());
    thirdStepProgramBlock.innerHTML = ``;
    const dataProgram = createProgramListTemplate(createProgramArray());
    renderElement(thirdStepProgramBlock, createElement(createProgramTitleTemplate()));
    renderElement(thirdStepProgramBlock, createElement(dataProgram));
    if (findActiveInputs(thirdStepBlockForm).length > 0) {
      btnNext.disabled = false;
      innerPrice(thirdStepBlock, price);
      innerPrice(thirdStepMobileBtn, price);
    } else {
      btnNext.disabled = true;
      innerPrice(thirdStepBlock, price);
      innerPrice(thirdStepMobileBtn, price);
    }
  });
  btnPrev.addEventListener(`click`, () => {
    showStep(thirdStepBlock, secondStepBlock);
    changeBarState(2);
    setTimeout(() => {
      thirdStepBlockForm.reset();
      price = priceSecondStep(); //summPrice(findAllActiveInputs());
      innerPrice(secondStepBlock, price);
      btnNext.disabled = true;
    }, 600);
  });
  btnNext.addEventListener(`click`, () => {
    innerPrice(fifthStepBlock, price);
    showStep(thirdStepBlock, fifthStepBlock);
    changeBarState(5);
    setTimeout(() => {
      footer.classList.remove(`footer--mobile-hidden`);
    }, 600);
    fifthStepProgramBlock.innerHTML = ``;
    const dataProgram = createProgramListTemplate(createProgramArray());
    renderElement(fifthStepProgramBlock, createElement(createProgramTitleTemplate()));
    renderElement(fifthStepProgramBlock, createElement(dataProgram));
    setTimeout(() => {
      initSlider(calculationStepsSliderBlock, calculationSliderSettings);
    }, 600);
    /*innerPrice(fourthStepBlock, price);
    innerPrice(fourthStepMobileBtn, price);
    showStep(thirdStepBlock, fourthStepBlock);
    changeBarState(4);
    fourthStep();
    fourthStepProgramBlock.innerHTML = ``;
    const dataProgram = createProgramListTemplate(createProgramArray());
    renderElement(fourthStepProgramBlock, createElement(createProgramTitleTemplate()));
    renderElement(fourthStepProgramBlock, createElement(dataProgram));*/
  });
};

/*const fourthStep = () => {
  const btnNext = fourthStepBlock.querySelector(`.js-next-step`);
  const btnPrev = fourthStepBlock.querySelector(`.js-prev-step`);
  let price = summPrice(findAllActiveInputs());
  fourthStepBlockForm.addEventListener(`change`, () => {
    fourthStepProgramBlock.innerHTML = ``;
    const dataProgram = createProgramListTemplate(createProgramArray());
    renderElement(fourthStepProgramBlock, createElement(createProgramTitleTemplate()));
    renderElement(fourthStepProgramBlock, createElement(dataProgram));
    price = summPrice(findAllActiveInputs());
    if (findActiveInputs(fourthStepBlockForm).length > 0) {
      innerPrice(fourthStepBlock, price);
      innerPrice(fourthStepMobileBtn, price);
      btnNext.innerText = `Далее`;
    } else {
      innerPrice(fourthStepBlock, price);
      innerPrice(fourthStepMobileBtn, price);
      btnNext.innerText = `Нет, спасибо`;
    }
  });
  btnPrev.addEventListener(`click`, () => {
    showStep(fourthStepBlock, thirdStepBlock);
    changeBarState(3);
    setTimeout(() => {
      fourthStepBlockForm.reset();
      price = summPrice(findAllActiveInputs());
      innerPrice(thirdStepBlock, price);
      btnNext.innerText = `Нет, спасибо`;
    }, 600);
  });
  btnNext.addEventListener(`click`, () => {
    innerPrice(fifthStepBlock, price);
    showStep(fourthStepBlock, fifthStepBlock);
    changeBarState(5);
    setTimeout(() => {
      footer.classList.remove(`footer--mobile-hidden`);
    }, 600);
    fifthStepProgramBlock.innerHTML = ``;
    const dataProgram = createProgramListTemplate(createProgramArray());
    renderElement(fifthStepProgramBlock, createElement(createProgramTitleTemplate()));
    renderElement(fifthStepProgramBlock, createElement(dataProgram));
    setTimeout(() => {
      initSlider(calculationStepsSliderBlock, calculationSliderSettings);
    }, 600);
  });
};*/

const calculateProgram = () => {
  if (stepBlocks.length) {
    firstStep();
  }
};

export default calculateProgram;
