import {initSlider} from '../swiper-settings';

const sliderWraper = document.querySelectorAll('.swiper-wrapper');
const womenSliderBlock = document.querySelector('.checkup__slider-woman');
const menSliderBlock = document.querySelector('.checkup__slider-man');
const adultProgramSliderBlock = document.querySelector('.program__slider-adult');
const childProgramSliderBlock = document.querySelector('.program__slider-child');
const childrenSliderBlock = document.querySelector('.checkup__slider-children');
const moreSliderBlock = document.querySelector('.checkup__slider-more');
const stuffSliderBlock = document.querySelector('.checkup__stuff-slider');

function sliderInitializer(element) {
  if (element) {
    initSlider(element, {
      slidesPerView: 'auto',
      navigation: {
        nextEl: element.querySelector('.swiper-button-next'),
        prevEl: element.querySelector('.swiper-button-prev')
      }
    });
  }
}

const initServicesPageSliders = () => {
  if (sliderWraper.length) {
    sliderInitializer(womenSliderBlock);
    sliderInitializer(menSliderBlock);
    sliderInitializer(adultProgramSliderBlock);
    sliderInitializer(childProgramSliderBlock);
    sliderInitializer(childrenSliderBlock);
    sliderInitializer(moreSliderBlock);

    if (stuffSliderBlock) {
      initSlider(stuffSliderBlock, {
        speed: 600,
        slidesPerView: 'auto',
        pagination: {
          el: '.stuff-slider__slider-pagination',
          clickable: true
        },
        navigation: {
          nextEl: stuffSliderBlock.querySelector('.swiper-button-next'),
          prevEl: stuffSliderBlock.querySelector('.swiper-button-prev')
        }
      });
    }
  }
};

export default initServicesPageSliders;
