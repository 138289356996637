const headerInfoBlock = document.querySelector('.header-info');
const wrapper = document.querySelector('.wrapper');
const errorPage = document.querySelector('.error-page');

let headerInfoBlockHeight;

const initPaddingforBannerOnErrPage = () => {
  if (errorPage) {
    const setPaddingforErrPage = () => {
      if (headerInfoBlock) {
        headerInfoBlockHeight = headerInfoBlock.getBoundingClientRect().height;
        wrapper.style.paddingTop = `${headerInfoBlockHeight}px`;
      }
    };

    window.addEventListener('resize', () => {
      setPaddingforErrPage();
    });
  }
};

export {initPaddingforBannerOnErrPage};
