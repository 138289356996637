const doctorsElement = document.querySelectorAll('[data-select="doctor"] li');

const filterClinic = (li) => {
  const form = li.closest('form'),
    clinics = li.dataset?.clinics,
    clinicLi = form.querySelectorAll('[data-select="clinic"] li');

  if (clinicLi) {
    if (clinics) {
      let clinicsId = clinics.split(",");
      clinicLi.forEach((li) => {
        li.style.display = clinicsId.includes(li.dataset.clinicid) ? 'list-item' : 'none';
      });
    } else {
      clinicLi.forEach((li) => {
        li.style.display = 'list-item';
      });
    }
  }

};

const selectDoctor = () => {
  doctorsElement.forEach((li) =>
    li.addEventListener('click', (evt) => {
      filterClinic(li)
    })
  );
};

export default selectDoctor;
