import {activateModal, initializeModal, dynamicModal} from './modals';
import {initInstagramModal} from './instagram-modal';

const modals = document.querySelectorAll('.modal');
const makeAppointmentModal = document.querySelector(`.js-make-appointment-modal`);
const makeAppointmentBtns = document.querySelectorAll(`.js-make-appointment-btn`);
const InstagramModal = document.querySelector('.js-make-cta-modal');
const callDoctorModal = document.querySelector(`.js-call-doctor-modal`);
const callDoctorBtns = document.querySelectorAll(`.js-call-doctor-btn`);
const tabDoctorModal = document.querySelector(`.js-tab-doctor-modal`);
const tabDoctorBtns = document.querySelectorAll(`.js-tab-doctor-btn`);
const searchDoctorsModal = document.querySelector(`.js-search-doctors-modal`);
const searchDoctorsBtns = document.querySelectorAll(`.js-doctors-search`);
const faqFilterModal = document.querySelector(`.js-faq-filter-modal`);
const faqFilterModalBtns = document.querySelectorAll(`.faq .js-modal-open`);
const faqAskModal = document.querySelector(`.js-faq-ask-modal`);
const faqAskModalBtns = document.querySelectorAll(`.js-faq-ask-modal-btn`);
const checkupMoreInfoModal = document.querySelector(`.js-checkup-more-info-modal`);
const checkupMoreInfoBtn = document.querySelectorAll(`.js-checkup-more-info-btn`);
const diseaseModalBtns = document.querySelectorAll(`.js-disease-search`);
const diseaseModal = document.querySelector(`.js-disease-modal`);
const diseaseFilterModalBtns = document.querySelectorAll(`.js-tab-disease`);
const diseaseFilterModal = document.querySelector(`.js-search-disease-modal`);
const dentistryModal = document.querySelector(`.js-stomatologiya-modal`);
const dentistryBtns = document.querySelectorAll(`.js-stomatologiya-btn`);
const homeCareModal = document.querySelector(`.js-pomoshch-na-domu-modal`);
const homeCareBtns = document.querySelectorAll(`.js-pomoshch-na-domu-btn`);
const outpatientCareModal = document.querySelector(`.js-ambulatorno-poliklinicheskaya-pomoshch-modal`);
const outpatientCareBtns = document.querySelectorAll(`.js-ambulatorno-poliklinicheskaya-pomoshch-btn`);
const gospitalizatsiya3Modal = document.querySelector(`.js-gospitalizatsiya-na-3-dnya-modal`);
const gospitalizatsiya3Btns = document.querySelectorAll(`.js-gospitalizatsiya-na-3-dnya-btn`);
const gospitalizatsiya5Modal = document.querySelector(`.js-gospitalizatsiya-na-5-dney-modal`);
const gospitalizatsiya5Btns = document.querySelectorAll(`.js-gospitalizatsiya-na-5-dney-btn`);
const renderModal = document.querySelector(`.js-tooltip-modal`);
const renderBtns = document.querySelectorAll(`.js-tooltip-modal-btn`);
const renderResultModal = document.querySelector(`.js-result-modal`);
const renderResultBtns = document.querySelectorAll(`.js-result-modal-btn`);
const downloadModal = document.querySelector(`.js-download-modal`);
const downloadBtns = document.querySelectorAll(`.js-download-modal-btn`);
const reviewsModal = document.querySelector(`.js-add-reviews-modal`);
const reviewsdBtns = document.querySelectorAll(`.js-reviews-modal-btn`);
const filterReviewsModal = document.querySelector(`.js-reviews-filter-modal`);
const filterReviewsdBtns = document.querySelectorAll(`.reviews .js-modal-open`);
const mobileMenuModal = document.querySelector(`.js-mobile-menu-popup`);
const mobileMenuBtns = document.querySelectorAll(`.js-mobile-menu-popup-btn`);
const ambulanceModal = document.querySelector(`.js-ambulance-modal-modal`);
const ambulanceBtns = document.querySelectorAll(`.js-ambulance-btn`);
const videoModal = document.querySelector(`.js-video-modal`);
const videoBtns = document.querySelectorAll(`.js-video-btn`);
const individualProgramApplicationModal = document.querySelector(`.js-individual-program-application-modal`);
const individualProgramApplicationBtns = document.querySelectorAll(`.js-individual-program-application-btn`);
const individualProgramCalculationModal = document.querySelector(`.js-individual-program-calculation-modal`);
const individualProgramCalculationBtns = document.querySelectorAll(`.js-individual-program-calculation-btn`);
const sendPatientModal = document.querySelector(`.js-send-patient-modal`);
const sendPatientBtns = document.querySelectorAll(`.js-send-patient-btn`);
const sendPatientTreatmentModal = document.querySelector(`.js-send-patient-treatment-modal`);
const sendPatientTreatmentBtns = document.querySelectorAll(`.js-send-patient-treatment-btn`);
const sendPatientHospitalizationModal = document.querySelector(`.js-send-patient-hospitalization-modal`);
const sendPatientHospitalizationBtns = document.querySelectorAll(`.js-send-patient-hospitalization-btn`);
const secondOpinionModal = document.querySelector(`.js-second-opinion-modal`);
const secondOpinionBtns = document.querySelectorAll(`.js-second-opinion-btn`);
const feedbackModal = document.querySelector(`.js-feedback-modal`);
const feedbackBtns = document.querySelectorAll(`.js-feedback-btn`);
const authorBlogModal = document.querySelector(`.js-author-blog-modal`);
const authorBlogBtns = document.querySelectorAll(`.js-author-blog-btn`);
const partnerModal = document.querySelector('.js-partner-modal');
const partnerBtns = document.querySelectorAll('.js-partner-btn');
const emptyModal = document.querySelector(`.js-empty-modal`);
const emptyBtns = document.querySelectorAll(`.js-empty-btn`);
const serviceModal = document.querySelector(`.js-service-form-modal`);
const serviceBtns = document.querySelectorAll(`.js-service-form-btn`);
const reviewMoreModal = document.querySelector(`.js-review-more-modal`);
const reviewMoreBtn = document.querySelectorAll(`.js-review-more-btn`);
const conferenceModal = document.querySelector('.js-conference-form-modal');
const conferenceBtn = document.querySelectorAll('.js-conference-form-btn');
const callbackModal = document.querySelector('.js-callback-modal');
const callbackBtn = document.querySelectorAll('.js-callback-btn');


const startPlay = ({target}) => {
  const parent = target.closest(`.js-video-btn`);
  const dataSrc = parent.dataset.src;
  const videoFrame = videoModal.querySelector(`iframe`);
  if (dataSrc === videoFrame.src) {
    setTimeout(() => {
      videoFrame.contentWindow.postMessage(`{"event":"command","func":"playVideo","args":""}`, `*`);
    }, 301);

    return;
  }
  videoFrame.src = dataSrc;
  setTimeout(() => {
    videoFrame.contentWindow.postMessage(`{"event":"command","func":"playVideo","args":""}`, `*`);
  }, 301);
};

const stopPlay = () => {
  const videoFrame = videoModal.querySelector(`iframe`);
  videoFrame.contentWindow.postMessage(`{"event":"command","func":"pauseVideo","args":""}`, `*`);
};

const initModals = () => {
  window.addEventListener('load', () => {
    if (modals.length) {
      modals.forEach((el) => {
        setTimeout(() => {
          el.classList.remove('modal--preload');
        }, 100);
      });
    }
  });
  if (individualProgramApplicationModal && individualProgramApplicationBtns.length) {
    activateModal(individualProgramApplicationModal, individualProgramApplicationBtns, false, false);
  }
  if (individualProgramCalculationModal && individualProgramCalculationBtns.length) {
    activateModal(individualProgramCalculationModal, individualProgramCalculationBtns, false, false);
  }
  if (videoModal && videoBtns.length) {
    activateModal(videoModal, videoBtns, startPlay, stopPlay);
  }
  if (ambulanceModal && ambulanceBtns.length) {
    activateModal(ambulanceModal, ambulanceBtns, false, false);
  }
  if (makeAppointmentModal && makeAppointmentBtns.length) {
    activateModal(makeAppointmentModal, makeAppointmentBtns, false, false);
  }
  if (InstagramModal) {
    initInstagramModal();
    initializeModal(InstagramModal, false);
  }
  if (callDoctorModal && callDoctorBtns.length) {
    activateModal(callDoctorModal, callDoctorBtns, false, false);
  }
  if (tabDoctorModal && tabDoctorBtns.length) {
    activateModal(tabDoctorModal, tabDoctorBtns, false, false);
  }
  if (searchDoctorsModal && searchDoctorsBtns.length) {
    activateModal(searchDoctorsModal, searchDoctorsBtns, false, false);
  }
  if (faqFilterModal && faqFilterModalBtns.length) {
    activateModal(faqFilterModal, faqFilterModalBtns, false, false);
  }
  if (faqAskModal && faqAskModalBtns.length) {
    activateModal(faqAskModal, faqAskModalBtns, false, false);
  }
  if (checkupMoreInfoModal && checkupMoreInfoBtn.length) {
    activateModal(checkupMoreInfoModal, checkupMoreInfoBtn, false, false);
  }
  if (diseaseModal && diseaseModalBtns.length) {
    activateModal(diseaseModal, diseaseModalBtns, false, false);
  }
  if (diseaseFilterModal && diseaseFilterModalBtns.length) {
    activateModal(diseaseFilterModal, diseaseFilterModalBtns, false, false);
  }
  if (dentistryModal && dentistryBtns.length) {
    activateModal(dentistryModal, dentistryBtns, false, false);
  }
  if (homeCareModal && homeCareBtns.length) {
    activateModal(homeCareModal, homeCareBtns, false, false);
  }
  if (outpatientCareModal && outpatientCareBtns.length) {
    activateModal(outpatientCareModal, outpatientCareBtns, false, false);
  }
  if (gospitalizatsiya3Modal && gospitalizatsiya3Btns.length) {
    activateModal(gospitalizatsiya3Modal, gospitalizatsiya3Btns, false, false);
  }
  if (gospitalizatsiya5Modal && gospitalizatsiya5Btns.length) {
    activateModal(gospitalizatsiya5Modal, gospitalizatsiya5Btns, false, false);
  }
  if (renderModal && renderBtns.length) {
    activateModal(renderModal, renderBtns, false, false);
  }
  if (renderResultModal && renderResultBtns.length) {
    activateModal(renderResultModal, renderResultBtns, false, false);
  }
  if (downloadModal && downloadBtns.length) {
    activateModal(downloadModal, downloadBtns, false, false);
  }
  if (reviewsModal && reviewsdBtns.length) {
    activateModal(reviewsModal, reviewsdBtns, false, false);
  }
  if (filterReviewsModal && filterReviewsdBtns.length) {
    activateModal(filterReviewsModal, filterReviewsdBtns, false, false);
  }
  if (mobileMenuModal && mobileMenuBtns.length) {
    activateModal(mobileMenuModal, mobileMenuBtns, false, false);
  }
  if (sendPatientModal && sendPatientBtns.length) {
    activateModal(sendPatientModal, sendPatientBtns, false, false);
  }
  if (secondOpinionModal && secondOpinionBtns.length) {
    activateModal(secondOpinionModal, secondOpinionBtns, false, false);
  }
  if (sendPatientTreatmentModal && sendPatientTreatmentBtns.length) {
    activateModal(sendPatientTreatmentModal, sendPatientTreatmentBtns, false, false);
  }
  if (sendPatientHospitalizationModal && sendPatientHospitalizationBtns.length) {
    activateModal(sendPatientHospitalizationModal, sendPatientHospitalizationBtns, false, false);
  }
  if (feedbackModal && feedbackBtns.length) {
    activateModal(feedbackModal, feedbackBtns, false, false);
  }
  if (authorBlogModal && authorBlogBtns.length) {
    dynamicModal(authorBlogModal, "js-author-blog-btn");
    //activateModal(authorBlogModal, authorBlogBtns, false, false);
  }
  if (partnerModal && partnerBtns.length) {
    activateModal(partnerModal, partnerBtns, false, false);
  }
  if (emptyModal && emptyBtns.length) {
    activateModal(emptyModal, emptyBtns, false, false);
  }
  if (serviceModal && serviceBtns.length) {
    activateModal(serviceModal, serviceBtns, false, false);
  }
  if (reviewMoreModal && reviewMoreBtn.length) {
    activateModal(reviewMoreModal, reviewMoreBtn, false, false);
  }
  if (conferenceModal && conferenceBtn.length) {
    activateModal(conferenceModal, conferenceBtn, false, false);
  }
  if (callbackModal && callbackBtn.length) {
    activateModal(callbackModal, callbackBtn, false, false);
  }
};

export default initModals;
