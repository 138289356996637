import {getParent} from './utils';


const ENTER_KEY_CODE = 13;
const accordionButtons = document.querySelectorAll(`.accordion__item-header`);
const accordions = document.querySelectorAll(`.accordion__item`);

const closeAccordion = () => {
  accordions.forEach((el) => {
    el.classList.remove(`js-show`);
  });
};

const documentClickHandler = (evt) => {
  if (getParent(evt.target, `js-show`)) {
    closeAccordion();
    document.removeEventListener(`click`, documentClickHandler);
  }
};

const accordionAction = () => {
  if (accordions.length) {

    accordionButtons.forEach((el) => {
      el.addEventListener(`click`, () => {
        if (!el.parentNode.classList.contains(`js-show`)) {
          closeAccordion();
        }
      });
    });

    const openAccordion = (evt) => {
      evt.preventDefault();
      document.addEventListener(`click`, documentClickHandler);
      if (evt.currentTarget.parentNode.classList.contains(`js-show`)) {
        closeAccordion();
      } else {
        evt.currentTarget.parentNode.classList.add(`js-show`);
      }
    };

    const openAccordionOnKeydown = (evt) => {
      document.addEventListener(`click`, documentClickHandler);
      if (evt.keyCode === ENTER_KEY_CODE) {
        evt.preventDefault();
        if (evt.target.parentNode.classList.contains(`js-show`)) {
          closeAccordion();
        } else {
          evt.target.parentNode.classList.add(`js-show`);
        }
      }
    };

    accordionButtons.forEach((accordionButton) => {
      accordionButton.addEventListener(`click`, openAccordion);
      accordionButton.addEventListener(`keydown`, openAccordionOnKeydown);
    });
  }
};

export default accordionAction;
