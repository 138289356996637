import {returnParent} from './utils';

const accordionTab = document.querySelector('.js-tab-acardion');
const priceListAccordion = document.querySelector('.price-list');

// eslint-disable-next-line no-undef
const moveTo = new MoveTo({
  duration: 1000,
  easing: `easeOutQuart`,
});

const tabAcardion = () => {
  if (accordionTab) {
    const link = accordionTab.querySelectorAll(`.tab-acardion__link`);
    link.forEach((el) => {
      el.addEventListener('click', (evt) => {
        evt.preventDefault();
        const contentWrapper = el.nextElementSibling;
        const parent = returnParent(evt.target, `tab-acardion__item`);
        if (!parent.classList.contains(`tab-acardion__item--active`)) {
          parent.classList.add(`tab-acardion__item--active`);
          contentWrapper.style.maxHeight = contentWrapper.scrollHeight + `px`;
          moveTo.move(el);
        } else {
          parent.classList.remove(`tab-acardion__item--active`);
          contentWrapper.style.maxHeight = null;
          moveTo.move(accordionTab);
        }
      });
    });
  }

  if (priceListAccordion) {
    const buttons = document.querySelectorAll('.price-list__accordion-title');
    const PRICE_ACTIVE_CLASS = 'price-list__accordion--active';
    buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        const active = button.closest('.price-list__accordion');

        if (active.classList.contains(PRICE_ACTIVE_CLASS)) {
          active.classList.remove(PRICE_ACTIVE_CLASS);
          return;
        }

        const parent = button.closest('.price-list');
        const blocks = parent.querySelectorAll('.price-list__accordion');

        blocks.forEach((block) => {
          block.classList.remove(PRICE_ACTIVE_CLASS);
        });

        active.classList.add(PRICE_ACTIVE_CLASS);
      });
    });
  }
};

export default tabAcardion;
