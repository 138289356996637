const sliderWraper = document.querySelectorAll(`.swiper-wrapper`);
const forPatientsQuotesSlider = document.querySelector(`.for-patients__quotes-slider`);

let swiperList = [];

const quotesSliderDescktopSettings = {
  slidesPerView: `auto`,
  spaceBetween: 30,
  navigation: {
    nextEl: `.swiper-button-next`,
    prevEl: `.swiper-button-prev`,
  },
};

const quotesSliderTabletSettings = {
  slidesPerView: `auto`,
  spaceBetween: 17,
  navigation: {
    nextEl: `.swiper-button-next`,
    prevEl: `.swiper-button-prev`,
  },
};

const quotesSliderMobileSettings = {
  slidesPerView: 1,
  spaceBetween: 30,
  updateOnWindowResize: true,
  pagination: {
    el: `.quotes-slider__slider-pagination`,
    clickable: true
  },
};


const initQuotesSlider = (el, settings) => {
  // eslint-disable-next-line no-undef
  const swiper = new Swiper(el, settings);
  swiperList.push(swiper);
};

const breakpoint = window.matchMedia(`(max-width:1023px)`);
const breakpointTablet = window.matchMedia(`(max-width:1152px) and (min-width:1024px)`);

const initQuotesSliders = () => {
  if (sliderWraper.length) {
    if (breakpoint.matches === true) {
      if (forPatientsQuotesSlider) {
        initQuotesSlider(forPatientsQuotesSlider, quotesSliderMobileSettings);
      }
    } else if (breakpointTablet.matches === true) {
      if (forPatientsQuotesSlider) {
        initQuotesSlider(forPatientsQuotesSlider, quotesSliderTabletSettings);
      }
    } else {
      if (forPatientsQuotesSlider) {
        initQuotesSlider(forPatientsQuotesSlider, quotesSliderDescktopSettings);
      }
    }
  }
};

const checkmobileWidth = () => {
  if (breakpoint.matches === true) {
    return true;
  }
  return false;
};

const checktabletWidth = () => {
  if (breakpointTablet.matches === true) {
    return true;
  }
  return false;
};

let isMobileWidth = checkmobileWidth();
let isTabletWidth = checktabletWidth();

const resizeQuotesSwiper = () => {
  if ((checkmobileWidth() !== isMobileWidth) || (checktabletWidth() !== isTabletWidth)) {
    isMobileWidth = checkmobileWidth();
    isTabletWidth = checktabletWidth();
    swiperList.forEach(function (swiper) {
      swiper.destroy();
    });
    swiperList = [];
    initQuotesSliders();
  }
};

window.addEventListener(`resize`, resizeQuotesSwiper);

export default initQuotesSliders;
