const listingSliders = document.querySelectorAll(`.clinic-address__slider.swiper-container`);
const innerSliders = document.querySelectorAll(`.clinic-address-inner__slider.swiper-container`);
const initListingSlider = () => {
  if (listingSliders.length) {
    listingSliders.forEach((el) => {
      // eslint-disable-next-line no-undef
      const newSlider = new Swiper(el, {
        updateOnWindowResize: true,
        slidesPerView: 4,
        sliderPerGroup: 1,
        spaceBetween: 30,
        initialSlide: 0,
        speed: 1000,
        navigation: {
          nextEl: el.querySelector(`.swiper-button-next`),
          prevEl: el.querySelector(`.swiper-button-prev`),
        },

        breakpoints: {
          1366: {
            slidesPerView: 4,
            sliderPerGroup: 1,
          },
          1024: {
            slidesPerView: 3,
            sliderPerGroup: 1,
            spaceBetween: 18,
          },
          768: {
            slidesPerView: 2,
            sliderPerGroup: 1,
            spaceBetween: 30,
          },
          320: {
            slidesPerView: 1.10,
            sliderPerGroup: 1,
            spaceBetween: 8,
          }
        }
      });
    });
  }
  if (innerSliders.length) {
    innerSliders.forEach((el) => {
      // eslint-disable-next-line no-undef
      const newSlider = new Swiper(el, {
        updateOnWindowResize: true,
        slidesPerView: 3,
        sliderPerGroup: 1,
        spaceBetween: 30,
        initialSlide: 0,
        speed: 1000,
        navigation: {
          nextEl: el.querySelector(`.swiper-button-next`),
          prevEl: el.querySelector(`.swiper-button-prev`),
        },

        breakpoints: {
          1800: {
            slidesPerView: 3,
            sliderPerGroup: 1,
          },
          1366: {
            slidesPerView: 2,
            sliderPerGroup: 1,
          },
          768: {
            slidesPerView: 2,
            sliderPerGroup: 1,
            spaceBetween: 30,
          },
          320: {
            slidesPerView: 1.10,
            sliderPerGroup: 1,
            spaceBetween: 8,
          }
        }
      });
    });
  }
};

export default initListingSlider;
