import initPolyfill from "./polyfill/polyfill";
import initSvgxuse from "./polyfill/svgxuse";
import initMMenu from "./modules/init-m-menu";
import moveToHeader from "./modules/move-to-header";
import validatePhone from "./modules/form/validate-phone";
import {customSelectAction} from "./modules/form/input-select";
// import initCustomCalendar from "./modules/form/init-custom-calendar";
import numInputAction from "./modules/form/input-num";
import {onClickTabLinks, initTabStateFromHash, initGmsTabs, initPregnancyTabs} from "./modules/tab";
import accordion from "./modules/accordion-tab";
import accordionAction from "./modules/accordion";
import inputFileAction from "./modules/form/input-file";
import initMainPageSliders from "./modules/page-main/init-sliders";
import storytellingModalAction from "./modules/page-main/storytelling-modal";
import menuModalAction from "./modules/page-main/menu-modal";
import searchModalAction from "./modules/page-main/search-modal";
import activateSwiper from "./modules/swiper-for-blog";
import setFormMode from "./modules/form/select-patient-category";
import initListingSlider from "./modules/listing-slider";
import initClinicSlider from "./modules/clinic-slider";
import modalAction from "./modules/mobile-modal";
import initQuotesSlider from "./modules/init-quotes-slider";
import initStuffSliders from "./modules/init-stuff-slider";
import setDoctorStatisticsSlider from "./modules/page-doctor/init-sliders";
import fixAsideBlock from "./modules/fix-aside";
import initDoctorsSearch from "./modules/page-doctor/doctors-search";
import initModals from "./modules/init-modals";
import setexpendedServicesCardSlider from "./modules/page-direction/init-sliders";
import changeDoctorsFormState from "./modules/page-doctors/doctors-form-filter";
// import specializationsModalAction from "./modules/page-doctors/specializations-modal";
// import doctorsModalAction from "./modules/page-doctors/doctors-modal";
import {setBlockState} from "./modules/page-doctors/page-doctor-action";
import {showMore} from "./modules/show-more";
import initDirectionsSearch from "./modules/page-direction/direction-search";
import changeDirectionsFormState from "./modules/page-direction/letter-sort";
import closeHeaderInfo from "./modules/page-services/header-info";
import initServicesPageSliders from "./modules/page-services/init-sliders";
import onClickTabHorizontalLinks from "./modules/page-services/tab-horizontal";
import initServicesSearch from "./modules/page-services/search";
import tabsSliderInit from "./modules/tabs-slider";
import tooltipAction from "./modules/page-program-calculation/tooltip";
import calculateProgram from "./modules/page-program-calculation/calculation-steps";
import renderTooltipModalContent from "./modules/page-program-calculation/render-tooltip-modal";
import renderResultModalContent from "./modules/page-program-calculation/render-result-modal";
import initLogoSlider from "./modules/logo-slider";
import setAsideOffset from "./modules/page-media/set-aside-offset";
import mobileMenuAction from "./modules/mobile-menu";
import setYtVideo from './modules/ytb-video';
import setModalHeight from "./modules/set-modal-height";
import activeTabSortBtn from "./modules/sort-tab";
import {initYoutubePlayer} from "./modules/youtube-video";
import {initVkPlayer} from "./modules/vk-video";
import customPlaceholderAction from "./modules/form/custom-placeholder";
import initObjectFitImages from "./polyfill/object-fit-images";
import initIE11CustomPropertiesPolyfill from "./polyfill/ie11-custom-properties";
import giveSpaceToAuthorInfo from "./modules/page-patients/give-space-to-author-info";
import setBlindMod from "./modules/blind-mod";
// import initLoader from "./modules/init-loader";
import hyphenate from "./modules/hyphenate";
import "./modules/init-tab-resize";
import initFormValidate from "./modules/form/validate-form";
import {initRecaptha} from "./modules/form/recaptha";
import tabAcardion from "./modules/tab-acardion";
import initShowMore from "./modules/init-show-more";
import filterQuestions from "./modules/page-faq/filter-questions";
import addressCheckboxesAction from "./modules/form/toggle-residence-address-fields";
import paginatePage from './modules/paginate';
import {initShowMoreBtnOnTextBlock} from './modules/init-show-more-on-quote';
import {initShowMoreBtnOnTable} from './modules/init-show-more-on-table';
import {initFixHeader} from './modules/init-fix-header';
import {initPaddingforBannerOnErrPage} from './modules/page-error/init-banner-height';
import initFakeLink from "./modules/fake-link";
import {initAnalytics} from "./modules/analytics";
import selectDoctor from "./modules/form/select-doctor";
import initLoadMore from "./modules/load-more";
import {showModalFromHash} from './modules/modals';
import timeBlockAction from "./modules/form/time-block";
import {initFancybox} from "./modules/init-fancybox";
import {initTaxForm} from "./modules/form/init-tax";
import initInputFile from "./modules/form/input-file-fake";

// Utils
initPolyfill();
hyphenate();
initMMenu();
mobileMenuAction();
initSvgxuse();
initObjectFitImages();
initIE11CustomPropertiesPolyfill();
initFormValidate();
initRecaptha();
tabAcardion();
initFakeLink();
initTaxForm();
initInputFile();

// Loader
// initLoader();
selectDoctor();

// page-main
initMainPageSliders();
storytellingModalAction();
menuModalAction();
searchModalAction();

// page-doctor
setDoctorStatisticsSlider();
initDoctorsSearch();
initShowMore();

// page-profession
tooltipAction();
calculateProgram();
renderTooltipModalContent();
renderResultModalContent();

// page-doctors
//specializationsModalAction();
//doctorsModalAction();
changeDoctorsFormState();
setBlockState();

// page-services
closeHeaderInfo();
initServicesPageSliders();
onClickTabHorizontalLinks();
initServicesSearch();

// page-directions
document.addEventListener('readystatechange', () => {
  if (document.readyState == 'complete') {
    setexpendedServicesCardSlider();
    initTabStateFromHash();
  }
});

initDirectionsSearch();
changeDirectionsFormState();

// page about-company
setYtVideo();

// page page-patients
giveSpaceToAuthorInfo();

// page-error
initPaddingforBannerOnErrPage();

// Инициализация модальных окон
initModals();
showModalFromHash();
initFancybox();

moveToHeader();
validatePhone();
customSelectAction();
//initCustomCalendar();
numInputAction();
onClickTabLinks();
initGmsTabs();
initPregnancyTabs();
accordion();
accordionAction();
inputFileAction();
activateSwiper();
setFormMode();
initListingSlider();
initClinicSlider();
modalAction();
initQuotesSlider();
initStuffSliders();
tabsSliderInit();
initLogoSlider();
activeTabSortBtn();
initYoutubePlayer();
initVkPlayer();
setBlindMod();
initShowMoreBtnOnTextBlock();
initShowMoreBtnOnTable();
initFixHeader();
timeBlockAction();

// modalFaqAction();
fixAsideBlock();
showMore();
setModalHeight();

// page-media
setAsideOffset();

customPlaceholderAction();

// page-faq
filterQuestions();

// page-submit-documents
addressCheckboxesAction();

// paginate
paginatePage();
initLoadMore();

// analytics
initAnalytics();
